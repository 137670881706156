import ReactDOMServer from 'react-dom/server';
import React from 'react';
import styles from './styles';

export const body = () => {
  return (`${ReactDOMServer.renderToStaticMarkup(
    <body>
      <div className="layout-container" onMouseDown="DragScroller.StartScroll"
        onMouseMove="DragScroller.ScrollNow">
      </div>
    </body>
  )}`);
};

export const head = () => {
  return (`
    <head>
      <link href="https://fonts.googleapis.com/css?family=Cabin:400,700" rel="stylesheet">
      <link href="https://fonts.googleapis.com/css?family=Raleway:700" rel="stylesheet">
      <style media="screen" type="text/css">
          ${styles}
      </style>
    </head>
  `);
};
