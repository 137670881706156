import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import find from 'lodash/find';
import {cloneDeep} from 'lodash/lang';
import filter from 'lodash/filter';
import Item from './item';
import Status from './status';
import Details from '../components/details';
import isEmpty from 'lodash/isEmpty';
import Toolbar from './toolbar';
import {BeatLoader} from 'react-spinners'

import {assetDownloadURL} from '../actions/assetActions';

function sortByName(array) {
  if (!array) return null;

  return array.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    return -1;
  });
}

export default class Explorer extends Component {

  constructor(props) {
    super(props);
    this.onItemClick = this.onItemClick.bind(this);
    this.returnAsset = this.returnAsset.bind(this);
    this.dropzone = React.createRef();

    this.state = {
      selectedItem: {},
      selectedItems: [],
      currentFolder: '',
      path: [],
      files: [],
      accepted: [],
      rejected: [],
      statusMessage: '',
      confirmOverwrite: null,
      uploadErrors: [],
      disableUploadClick : true,
      showLoader: false,
      showDetailsLoader: false
    };

    this.uploadQueue = [];
  }

  showLoader = (value) => {
    this.setState({showLoader: value})
  }

  componentDidMount() {
    const {organizationId, actions: {GET_FOLDERS}} = this.props;

    return Promise.resolve()
      .then(() => this.showLoader(true))
      .then(() => GET_FOLDERS(organizationId))
      .then(() => this.showLoader(false))
      .catch(error => console.error('Failed to load folders: ', error))
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.assets.folders !== this.props.assets.folders) {
      !isEmpty(this.props.currentAsset)
        ? this.props.actions.GET_CURRENT_ASSET(this.props.currentAsset)
          .then(() => this.openToCurrentAsset())
          .catch(() => this.openRootFolder())
        : this.openLastAccessedFolder(nextProps);
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextState.selectedItems.length === 1) {
      if (this.state.selectedItem.uuid !== nextState.selectedItem.uuid) {
        nextState.selectedItem.type === 'file'
          ? this.getAssetMeta(nextState.selectedItem.uuid)
          : this.getFolderMeta(nextState.selectedItem.uuid);
      }
    }
  }

  componentDidUpdate (prevProps, prevState, snapShot) {
    /*Disable the default dropzone onClick functionality.
    The current version of dropzone does not give you the option of
    triggering the onClick manually, so this workaround is currently required.*/
    if (!this.state.disableUploadClick) {
      if (this.uploadClickEvent) {
        this.dropzone.current.onClick(this.uploadClickEvent);
        this.uploadClickEvent = undefined;
      }
      this.setState({disableUploadClick:true})
    }
  }

  getAssetMeta = (uuid) => {
    this.setState({showDetailsLoader:true})
    this.props.actions.GET_ASSET(uuid)
      .then(() => this.setState({showDetailsLoader: false}))
      .catch(() => this.setState({showDetailsLoader: false}))
  }

  getFolderMeta = (uuid) => {
    this.setState({showDetailsLoader:true})
    this.props.actions.GET_FOLDER_META(uuid)
      .then(() => this.setState({showDetailsLoader: false}))
      .catch(() => this.setState({showDetailsLoader: false}))
  }

  reloadContent = (keepMeta) => {
    this.state.currentFolder ? this.getFolderContent(this.state.currentFolder) : this.openRootFolder();
    !keepMeta && this.props.actions.RESET_META();
  };

  renameItem = (type, value, uuid) => {
    type === 'folder'
      ? this.props.actions.RENAME_FOLDER({name:value}, uuid)
        .then(() => this.getFolderMeta(uuid), this.reloadContent(true))
        .catch(() => this.onRenameFailed(value))
      : this.props.actions.RENAME_ASSET({name:value}, uuid)
        .then(() => this.getAssetMeta(uuid), this.reloadContent(true))
        .catch(() => this.onRenameFailed(value))
  }

  onRenameFailed = (value) => {
    /*TODO: make a notification for the user*/
    this.setState({newName: this.props.name})
  }

  getFolderContent = (folderId) =>
  {
    this.showLoader(true)
    this.props.actions.GET_FOLDER_CONTENT(folderId)
      .then(() => this.showLoader(false))
      .catch(error => {console.error('Failed load folder content: ', error); this.showLoader(false)});
  };

  openToCurrentAsset = () => {
    this.getFolderContent(this.props.assets.currentAsset.folder);
    this.setState({currentFolder: this.props.assets.currentAsset.folder})
  };

  openLastAccessedFolder = (props) => {
    let rootFolder = props.assets.last_accessed ||
      find(props.assets.folders, {parentId: null}).uuid;
    this.getFolderContent(rootFolder);
    this.setState({currentFolder: rootFolder});
  };

  getRootFolder = () => {
    const rootFolder = find(this.props.assets.folders, {parentId: null}).uuid;
    return rootFolder;
  }

  openRootFolder = () => {
    const rootFolder = this.getRootFolder();
    this.getFolderContent(rootFolder);
    this.setState({currentFolder: rootFolder});
  };

  findFolder(uuid) {
    return this.props.assets.folders.find(function (folder) {
      return folder.uuid === uuid
    });
  }

  moveToFolder = (uuid) => {
    this.props.actions.SET_LAST_ACCESSED_FOLDER(uuid);
    this.setState({currentFolder: uuid});
    this.getFolderContent(uuid);
    this.setState({path: [...this.state.path, this.findFolder(uuid).name + '/']})
    this.props.actions.RESET_META();
  };

  moveToParentFolder = () => {
    if (!this.state.currentFolder) {
      this.openRootFolder();
      return;
    }

    const parent = this.findFolder(this.state.currentFolder).parentId;

    if (parent === '' || parent === null) {
      // does not have a parent, it's a root folder
      return;
    }

    this.getFolderContent(parent);
    this.props.actions.SET_LAST_ACCESSED_FOLDER(parent);
    this.setState({currentFolder: parent});
    let path = [...this.state.path];
    path.splice(-1, 1);
    this.setState({path: path, selectedItems : []});
    this.props.actions.RESET_META();
  };


  downloadParams = () => {
    const {attr} = this.props;
    let params = [];

    if (attr.crop) {
      params.push(`crop=${attr.crop}`);
    }

    if (attr.size) {
      params.push(`size=${attr.size}`);
      params.push(`max=${attr.max || true}`);
      params.push(`min=${attr.min || false}`);
    }

    if (attr.ignoreAR) {
      params.push(`ignoreAR=${attr.ignoreAR}`);
    }

    return `?${params.join('&')}`;;
  };

  returnAsset(uuid, locale, assetData) {
    let attr = cloneDeep(this.props.attr);
    attr.type = assetData.type;

    const file = {
      uuid: uuid,
      download: assetDownloadURL(uuid, locale, this.downloadParams()),
      attr: {...attr}
    };

    this.props.callBack(file);
    this.props.onClose();
  }

  onItemClick(event, type, uuid, state) {
    event.stopPropagation();
    if (event.ctrlKey) {
      this.setState({selectedItems: [...this.state.selectedItems, uuid], selectedItem: {uuid: uuid, type: type}})
    } else {
      this.setState({selectedItems: [uuid], selectedItem: {uuid: uuid, type: type}})
    }
  }

  addErrorToList(filename, errorText) {
    this.setState({
      uploadErrors: this.state.uploadErrors.concat({
        file: filename,
        message: errorText
      })
    });
  }

  async uploadItem(item) {
    try {

      if (item.fileExists) {
        this.setState({
          confirmOverwrite: {
            filename: item.filename,
            callback: async (yes) => {

              if (yes) {
                await this.props.actions.UPLOAD_ASSET(item.data, item.folder, item.locale);
              } else {
                this.addErrorToList(item.filename, 'file already exists')
              }

              this.setState({confirmOverwrite: null});
              this.processUploadQueue();
            }
          }
        })
      } else {

        await this.props.actions.UPLOAD_ASSET(item.data, item.folder, item.locale);
        this.processUploadQueue();

      }

    } catch (error) {

      const errorMessage = error.response.status === 413
        ? 'File size too large'
        : error.response.statusText;

      this.addErrorToList(item.filename, errorMessage);

      this.processUploadQueue();
    }
  }

  async processUploadQueue() {
    const item = this.uploadQueue.shift();

    if (item) {
      this.setState({statusMessage: `Uploading: ${item.filename}`})
      await this.uploadItem(item);
    } else {
      this.reloadContent();
      this.setState({statusMessage: ''});
      return false;
    }

    return true;
  }

  dismissUploadErrors = () => {
    this.setState({uploadErrors: []})
  }

  onDrop = (accepted, rejected) => {
    accepted.forEach((file) => {
      let data = new FormData();
      data.append('file', file);

      const fileExists = this.props.assets.content.files.findIndex(
        currentFile => currentFile.name === file.name
      ) > -1;

      this.uploadQueue.push({
        data: data,
        folder: this.state.currentFolder,
        locale: this.props.attr.locale,
        filename: file.name,
        fileExists: fileExists
      });

    });

    this.processUploadQueue();

    this.setState({
      accepted: accepted, rejected: rejected
    });
  }

  filterAssets = (assets, locale) => {
    let filtered = [];
    if (assets) {
      for (let asset of assets) {
        const data = filter(asset.assetData, function (o) {
          return o.locale === locale
        })[0];

        if (!data) {
          // skip assets without matching locale
        } else if (data.type.startsWith(this.props.assets.typeFilter)) {
          filtered.push(asset);
        } else if (this.props.assets.typeFilter === 'other' &&
          !data.type.startsWith('image') &&
          !data.type.startsWith('audio') &&
          !data.type.startsWith('text')) {
          filtered.push(asset);
        }
      }
    }
    return sortByName(filtered);
  };

  fileUpload = (e) => {
    e.persist();
    this.uploadClickEvent = e;
    this.setState({disableUploadClick : false});
  }

  renderToolBar (assetData, folderData) {
    return (
      <Toolbar moveToParentFolder={this.moveToParentFolder}
        moveToFolder={this.moveToFolder}
        reloadContent={this.reloadContent}
        currentFolder={this.state.currentFolder}
        selectedItem={this.state.selectedItems.length === 1 && (
         assetData ? assetData : (folderData ? folderData : null))}
        type={this.state.selectedItems.length === 1 && (
         assetData ? 'asset' : (folderData ? 'folder' : null))}
        path={this.state.path}
        fileUpload={this.fileUpload}
        {...this.props}/>
    )
  }

  renderExplorer (assets, folders) {
    const that = this;
    const filtered = this.filterAssets(assets, this.props.attr.locale);
    return (
      <Dropzone className="explorer"
        ref={this.dropzone}
        onDrop={this.onDrop}
        disableClick={this.state.disableUploadClick}
        multiple={true}
      >
        {folders && folders.map(function (item) {
          return <Item
            key={item.uuid}
            type={'folder'}
            uuid={item.uuid}
            parent={that.state.currentFolder}
            name={item.name}
            selected={that.state.selectedItems.includes(item.uuid)}
            onClick={that.onItemClick}
            actions={that.props.actions}
            renameItem={that.renameItem}
            moveToFolder={that.moveToFolder}
            assets={that.props.assets}/>})
        }
        {filtered && filtered.map(function (item) {
          return <Item
            key={item.id}
            type={'file'}
            assetData={item.assetData}
            uuid={item.id}
            parent={item.parentId}
            name={item.name}
            locale={that.props.attr.locale}
            selected={that.state.selectedItems.includes(item.id)}
            onClick={that.onItemClick}
            actions={that.props.actions}
            returnAsset={that.returnAsset}
            renameItem={that.renameItem}
            modified={item.assetData[0].modified}
            assets={that.props.assets}/>})
        }
        <p className="explorer-text">
          {(assets && folders) && (assets.length === 0 && folders.length === 0) && 'Drop files here'}
        </p>
      </Dropzone>
    )
  }

  renderStatus () {
    return (
      <Status
        statusMessage={this.state.statusMessage}
        errorList={this.state.uploadErrors}
        dismissErrors={this.dismissUploadErrors}
        confirmOverwrite={this.state.confirmOverwrite} />
    )
  }

  renderDetails (assetData) {
    const that = this;
    return (
      <Details
        meta={this.state.selectedItems.length === 1 && this.props.assets.meta}
        showLoader={this.state.showDetailsLoader}
        returnAsset={that.returnAsset}
        actions={this.props.actions}
        selectedItem={this.state.selectedItem}
        assetData={assetData}
        renameItem={that.renameItem}
        assets={this.props.assets}/>
    )
  }

  renderLoader () {
    return (
      <div className="explorer">
        <div className="explorer-loader">
          <BeatLoader color="#0090a1"/>
        </div>
      </div>
    )
  }

  render() {

    const assets = this.props.assets.content.files;
    const folders = sortByName(this.props.assets.content.folders);

    const assetData = this.state.selectedItem && assets && assets.find((element) => {
      return element.id === this.state.selectedItem.uuid;
    });

    const folderData = this.state.selectedItem && folders && folders.find((element) => {
      return element.uuid === this.state.selectedItem.uuid;
    });

    const loading = this.state.showLoader;

    return (
      <div className="explorer-container">
        {this.renderToolBar(assetData, folderData)}
        <div className="explorer-path">{this.state.path}</div>
        {loading ? this.renderLoader(): this.renderExplorer(assets, folders)}
        {this.renderStatus()}
        {this.renderDetails(assetData)}
      </div>
    )
  }
}
