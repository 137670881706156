import {initActions} from '@dized/ui/dist/redux/ActionHelper';
import {filterByOrganizationId} from '@dized/ui/dist/redux/FilterHelper';
import {call, del, get, patch, post, postFile} from '@dized/ui/dist/redux/RequestHelper';

const asset = '/api/v1/assets/asset';
const folders = '/api/v1/assets/folders';

const assetPath = (uuid, locale, action) => `${asset}/${uuid}/${locale}/${action}`;
export const assetDownloadURL = (uuid, locale, query) => `${assetPath(uuid, locale, 'download')}${query}`;

export default initActions('AssetActions', {
  RESET_META: () => call(),
  SHOW_MODAL: show => call(show),
  RESET_CONTENT: () => call(),
  SET_LAST_ACCESSED_FOLDER: uuid => call(uuid),
  FILTER_ASSETS: filter => call(filter),
  GET_ASSET: uuid => get(`${asset}/${uuid}`),
  GET_CURRENT_ASSET: uuid => get(`${asset}/${uuid}`),
  DOWNLOAD_ASSET_LOCALE: (uuid, locale) => get(assetDownloadURL(uuid, locale, ''), undefined, {uuid}),
  UPLOAD_ASSET: (file, uuid, locale) => postFile(`${folders}/${uuid}/${locale}/upload`, file),
  DELETE_ASSET: uuid => del(`${asset}/${uuid}`),
  DELETE_FOLDER: uuid => del(`${folders}/${uuid}`),
  REPLACE_ASSET: (file, uuid, locale) => postFile(assetPath(uuid, locale, 'upload'), file),
  GET_FOLDERS: organizationId => get(folders, filterByOrganizationId(organizationId)),
  GET_FOLDER_META: uuid => get(`${folders}/${uuid}`),
  GET_FOLDER_CONTENT: uuid => get(`${folders}/${uuid}/ls`),
  CREATE_FOLDER: folder => post(folders, folder),
  RENAME_ASSET: (data, uuid) => patch(`${asset}/${uuid}`, data),
  RENAME_FOLDER: (data, uuid) => patch(`${folders}/${uuid}`, data),
});
