import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import QueryString from 'qs';

import UserActions from '../../actions/UserActions';

import './Login.css';
import logo from './dized.svg';
import {CREATE_TEST_ORGANIZATION_PATH} from '../Portal';

// TODO: Add localized strings https://playmoregms.atlassian.net/browse/DIZ-83
const mapStateToProps = store => ({
  invalidSession: store.session.invalidSession,
});

const mapDispatchToProps = dispatch => ({
  ...UserActions.bindActions(dispatch),
});

class Login extends Component {
  static propTypes = {
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
    invalidSession: PropTypes.bool,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        orgName: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    LOGIN: PropTypes.func.isRequired,
  };

  state = {
    errorMessage: null,
    password: '',
    username: '',
  };

  componentDidUpdate() {
    if (!this.props.invalidSession) {
      this.redirectToDashboard();
    }
  }

  /**
   * Extends React Component lifecycle API with a promisified version of setState()
   *
   * @param {object} newState - new component state passed to this.setState()
   * @returns {Promise}       resolves when component state has been updated
   */
  setStatePromise(newState) {
    return new Promise(resolve => this.setState(newState, resolve));
  }

  handleUsernameChange = event => this.setState({username: event.target.value});
  handlePasswordChange = event => this.setState({password: event.target.value});

  handleSubmit = event => {
    event.preventDefault();

    const {LOGIN} = this.props;
    const {username, password} = this.state;

    return Promise.resolve()
      .then(() => this.setStatePromise({errorMessage: null}))
      .then(() => LOGIN({username, password}))
      .then(() => this.handleLoginSuccess())
      .catch(error =>
        this.setStatePromise({
          errorMessage:
            error.response ? error.response.data.error.message :
              error.message ? error.message :
                // TODO: Localization
                'Please try entering your details again',
        })
      );
  };

  getReturnUri() {
    const {location: {search}} = this.props;
    const parsed = QueryString.parse(search, {ignoreQueryPrefix: true});
    return parsed.returnUri;
  }

  getInfoTextForReturnUri() {
    const returnUri = this.getReturnUri() || '';

    if (returnUri.match(new RegExp(`^/${CREATE_TEST_ORGANIZATION_PATH}.*`)))
      return returnUri.match(/\?newuser$/)
        ? ( // redirected from registration page
          <>
            <div>Your account has been created.</div>
            <div>Login again to create a test organization for it.</div>
          </>
        )
        : ( // all other accesses - "justify" looks better for the long text
          <div style={{textAlign: 'justify'}}>
            Login with your Dized user account to create yourself a Dized Organization.
            The Organizations are meant for tabletop game industry operators and Dized
            content creators.
            If you don&apos;t yet have a Dized user account you can create
            one <a href="https://portal.dized.com/register">here</a>
          </div>
        );

    return <div>Please login to access your organization</div>;
  }

  handleLoginSuccess() {
    const returnUri = this.getReturnUri();

    if (returnUri) {
      const {history} = this.props;
      history.replace(returnUri);
    } else {
      this.redirectToDashboard();
    }
  }

  redirectToDashboard() {
    const {history, match} = this.props;
    const orgName = match.params.orgName;
    history.replace(`/${orgName}/`);
  }

  render() {
    const {errorMessage, password, username} = this.state;

    return (
      <div className="Login">
        <div className="Login-central">
          <img src={logo} alt="Dized" />
          <div className="Login-info">
            {this.getInfoTextForReturnUri()}
          </div>
          <div className={`Login-error ${errorMessage ? 'Login-error-border' : ''}`}>
            {errorMessage}
          </div>
          <form onSubmit={this.handleSubmit}>
            <label className="Login-label">
                            Email address
              <input className="Login-textinput" type="Email" name="userid"
                placeholder="email" onChange={this.handleUsernameChange}
                value={username} required />
            </label>
            <label className="Login-label">
                            Password
              <input className="Login-textinput" type="password" name="password"
                placeholder="password" onChange={this.handlePasswordChange}
                value={password} required />
            </label>
            <input className="Login-button" type="submit" value="Login" />
          </form>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
