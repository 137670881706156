import GameActions from '../actions/GameActions';
import UserActions from '../actions/UserActions';
import {getStringFromTO} from '../common/utils/i18nUtils'

const initialState = {
  gameLookup: {},
  gameList: [],
  initialized: false
};

export default (store = initialState, action) => {
  const {payload} = action;

  switch (action.type) {
    case GameActions.GET_GAME.FULFILLED:
    case GameActions.GET_GAMES.FULFILLED: {
      const clonedGameLookupGetGames = JSON.parse(JSON.stringify(store.gameLookup));

      payload.data.forEach(game => {
        clonedGameLookupGetGames[game.uuid] = game;
      });

      const fullListInitialized = action.type === GameActions.GET_GAMES.FULFILLED;

      return {
        ...store,
        gameLookup: {...clonedGameLookupGetGames},
        gameList: getSortedList(clonedGameLookupGetGames),
        initialized: fullListInitialized ? true : store.initialized
      };
    }
    case GameActions.GET_LATEST_REVISION.FULFILLED: {
      const enrichedGameLookup = {};

      payload.data.forEach(version => {
        const game = {...store.gameLookup[version.gameId]};
        enrichedGameLookup[version.gameId] = {...game, ...version, versionId: version.id};
      });

      const mergedLookupGetVersion = {...store.gameLookup, ...enrichedGameLookup};

      return {
        ...store,
        gameLookup: mergedLookupGetVersion,
        gameList: getSortedList(mergedLookupGetVersion)
      };
    }
    case GameActions.POST_GAME.FULFILLED: {
      const data = payload.data;
      const mergedLookupPostGame = {
        ...store.gameLookup,
        [data.uuid]: {
          ...store.gameLookup[data.uuid],
          ...data
        }
      };

      return {
        ...store,
        gameLookup: mergedLookupPostGame,
        gameList: getSortedList(mergedLookupPostGame),
      };
    }
    case GameActions.POST_REVISION.FULFILLED: {
      const mergedLookupPostRevision = {
        ...store.gameLookup,
        [payload.data.gameId]: {
          ...store.gameLookup[payload.data.gameId],
          ...payload.data
        }
      };

      return {
        ...store,
        gameLookup: mergedLookupPostRevision,
        gameList: getSortedList(mergedLookupPostRevision),
      };
    }
    case GameActions.PUT_REVISION.FULFILLED: {
      const version = payload.data.revision;
      const gameId = version.gameId;
      const gameData = {
        ...store.gameLookup[gameId],
        ...version
      };
      const mergedLookupPutVersion = {
        ...store.gameLookup,
        [gameId]: {...gameData}
      };

      return {
        ...store,
        gameLookup: mergedLookupPutVersion,
        gameList: getSortedList(mergedLookupPutVersion)
      };
    }

    case UserActions.LOGOUT.FULFILLED:
      return {...initialState}; // reset state on logout

    default:
      return store;
  }
}

function titleSort(a, b) {
  return getStringFromTO(a.title).toUpperCase() > getStringFromTO(b.title).toUpperCase() ? 1 : -1;
}

function getSortedList(gameLookup) {
  let sortableList = [];

  Object.keys(gameLookup).forEach(function(key) {
    sortableList.push({
      key: key,
      title: gameLookup[key].title
    });
  });

  return sortableList.sort(titleSort).map((titleObject) => titleObject.key);
}
