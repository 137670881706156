import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import {createBrowserHistory} from 'history';
import {ConnectedRouter, routerMiddleware} from 'connected-react-router';
import {injectReducer} from 'redux-injector';
import {dynamicMiddlewaresInstance, store} from '@dized/ui/dist/redux/store';

import {ThemeProvider} from 'styled-components';
import theme from './common/styles/Theme';

import './index.css';
// NOTE: this must imported after the Redux store has been initialized
import Portal from './views/Portal';

import apiMiddleware from './middleware/apiMiddleware';
import reducers from './reducers';

const history = createBrowserHistory();

// inject application middlewares
dynamicMiddlewaresInstance.addMiddleware(routerMiddleware(history));
dynamicMiddlewaresInstance.addMiddleware(apiMiddleware);

// inject application reducers
Object
  .entries(reducers(history))
  .forEach(([key, reducer]) => injectReducer(key, reducer));

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        <Portal history={history} />
      </ConnectedRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
