import React from 'react';
import styled from 'styled-components';

import {Button, COLOR, GridDataTable as GDTable} from '@dized/ui';

export const GridButton = styled(Button)`
  margin: 0 5px;
  min-width: fit-content;
`;

const StyledGridDataTable = styled(GDTable)`
  border-left: 1px solid ${COLOR.LIGHT_GRAY};
  border-right: 1px solid ${COLOR.LIGHT_GRAY};
`;

export const GridDataTable = (props) => (
  <StyledGridDataTable
    rowStyle={(_, rowIndex) => rowIndex === 0
      ? `
        background-color: ${COLOR.LIGHT_GRAY};
      `
      : `
        border-bottom: 1px solid ${COLOR.LIGHT_GRAY};
        padding: 5px 1em;
      `
    }
    {...props}
  />
);

GridDataTable.propTypes = GDTable.propTypes;
