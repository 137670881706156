import ProjectActions from '../../actions/ProjectActions';
import RuleActions from '../../actions/RuleActions';
import TutorialActions from '../../actions/TutorialActions';
import {createProject, createRuleVersion} from '../rulesHelpers/entityUtils';

export const ProjectTypes = {
  EXTRA_CONTENT: 'extra-tct',
  EXTRA_RULES: 'extra-rlt',
  RULES_CT: 'rlt',
  TUTORIAL_CT: 'tct',
};

export const ProjectTypeNames = {
  [ProjectTypes.EXTRA_CONTENT]: 'Extra Content',
  [ProjectTypes.EXTRA_RULES]:   'Extra Rules',
  [ProjectTypes.RULES_CT]:      'Rules',
  [ProjectTypes.TUTORIAL_CT]:   'Tutorial',
};

export const ProjectCategories = {
  ADD_ON: 'add-on',
  RULES: '', // backward compatibility for old projects
  SCORING: 'scoring',
  TUTORIAL: '', // backward compatibility for old projects
};

const {GET_PROJECT, POST_PROJECT} = ProjectActions;
const {GET_VERSION, POST_VERSION} = RuleActions;
const {GET_TUTORIAL_JSON, POST_TUTORIAL} = TutorialActions;

const createNewProject = (organization, gameId, type, category) => {
  const {payload} = POST_PROJECT(
    createProject({
      uuid: gameId,
      title: 'First Edition',
      organizationId: organization.uuid,
      type,
      category,
    })
  );

  return payload
    .then(response => response.data.uuid);
};

const findOrCreateProject = (organization, gameId, type, category) => {
  const {payload} = GET_PROJECT(gameId, type, category);

  return payload
    .then(response => {
      if (response.data.length === 0)
        return createNewProject(organization, gameId, type, category);

      return response.data[0].uuid;
    });
};

const createNewRuleVersion = projectId => {
  const {payload} = POST_VERSION(
    createRuleVersion({
      uuid: projectId,
      title: 'v1.0',
    })
  );

  return payload
    .then(response => response.data.uuid);
};

const findOrCreateRuleVersion = projectId => {
  const {payload} = GET_VERSION(projectId);

  return payload
    .then(response => {
      if (response.data.length === 0)
        return createNewRuleVersion(projectId);

      return response.data[0].uuid;
    });
};

const createNewTutorial = (organization, gameId, projectId) => {
  const {payload} = POST_TUTORIAL({
    gameId,
    projectId,
    organization: organization.uuid,
  });

  return payload
    .then(() => projectId);
};

const findOrCreateTutorial = (organization, gameId, projectId) => {
  const {payload} = GET_TUTORIAL_JSON(projectId);

  return payload
    .then(response => {
      // 200 with errorCode set will be returned if tutorial doesn't exist
      if (response.data.errorCode)
        return createNewTutorial(organization, gameId, projectId);

      return projectId;
    });
};

const openURLInNewTab = url => window.open(url, '_blank', 'noopener');

const navigateToGameTranslations = (gameId, locale) =>
  openURLInNewTab(`${window.__SERVER_DATA__?.REACT_APP_TRANSLATION_TOOL ?? process.env.REACT_APP_TRANSLATION_TOOL}/game/${gameId}/${locale}`);

const navigateToProjectTranslations = (projectId, locale) =>
  openURLInNewTab(`${window.__SERVER_DATA__?.REACT_APP_TRANSLATION_TOOL ?? process.env.REACT_APP_TRANSLATION_TOOL}/project/${projectId}/${locale}`);

const navigateToRules = (organization, ruleId) =>
  openURLInNewTab(`${window.__SERVER_DATA__?.REACT_APP_RULES_CT ?? process.env.REACT_APP_RULES_CT}/${organization.slug}/version/${ruleId}`);

const navigateToTutorial = projectId =>
  openURLInNewTab(`${window.__SERVER_DATA__?.REACT_APP_TUTORIAL_CT ?? process.env.REACT_APP_TUTORIAL_CT}/editor/${projectId}`);

const navigateToTutorialReview = (projectId, locale) =>
  openURLInNewTab(`${window.__SERVER_DATA__?.REACT_APP_TUTORIAL_CT ?? process.env.REACT_APP_TUTORIAL_CT}/review/${projectId}${locale ? `/${locale}` : ''}`);

/**
 * Open the Translation Tool for a game. This will not create the game.
 *
 * @param {string} gameId - game UUID
 * @param {string} locale - locale to translate to
 * @returns {Promise}     resolves when new tool page is opened
 */
export const editGameTranslations = (gameId, locale) =>
  Promise.resolve()
    .then(() => navigateToGameTranslations(gameId, locale));

/**
 * Open the Content Tool for a project
 *
 * @param {object} organization - prop from redux state
 * @param {string} gameId       - game UUID
 * @param {string} projectId    - project UUID
 * @param {string} projectType  - project type
 * @returns {Promise}           resolves when new tool page is opened
 */
export const editProject = (organization, gameId, projectId, projectType) => {
  switch (projectType) {
    case ProjectTypes.EXTRA_RULES:
    case ProjectTypes.RULES_CT:
      return findOrCreateRuleVersion(projectId)
        .then(ruleId => navigateToRules(organization, ruleId));

    case ProjectTypes.EXTRA_CONTENT:
    case ProjectTypes.TUTORIAL_CT:
      return findOrCreateTutorial(organization, gameId, projectId)
        .then(() => navigateToTutorial(projectId));

    default:
      console.error(`Trying to edit a project with unknown type '${projectType}'`);
      return;
  }
};

/**
 * Open the Translation Tool for a project. This will not create the project.
 *
 * @param {string} projectId - project UUID
 * @param {string} locale    - locale to translate to
 * @returns {Promise}        resolves when new tool page is opened
 */
export const editProjectTranslations = (projectId, locale) =>
  Promise.resolve()
    .then(() => navigateToProjectTranslations(projectId, locale));

/**
 * Open the Rules CT for a project
 *
 * @param {object} organization - prop from redux state
 * @param {string} gameId       - game UUID
 * @returns {Promise}           resolves when new tool page is opened
 */
export const editRules = (organization, gameId) =>
  findOrCreateProject(organization, gameId, ProjectTypes.RULES_CT, ProjectCategories.RULES)
    .then(projectId => findOrCreateRuleVersion(projectId))
    .then(ruleId => navigateToRules(organization, ruleId));

/**
 * Open the Tutorial CT for a project
 *
 * @param {object} organization - prop from redux state
 * @param {string} gameId       - game UUID
 * @returns {Promise}           resolves when new tool page is opened
 */
export const editTutorial = (organization, gameId) =>
  findOrCreateProject(organization, gameId, ProjectTypes.TUTORIAL_CT, ProjectCategories.TUTORIAL)
    .then(projectId => findOrCreateTutorial(organization, gameId, projectId))
    .then(projectId => navigateToTutorial(projectId));

/**
 * Open the Tutorial CT for a project in review mode
 *
 * @param {object} organization - prop from redux state
 * @param {string} gameId       - game UUID
 * @param {string} [locale]     - optional locale string
 * @returns {Promise}           resolves when new tool page is opened
 */
export const reviewTutorial = (organization, gameId, locale) =>
  findOrCreateProject(organization, gameId, ProjectTypes.TUTORIAL_CT, ProjectCategories.TUTORIAL)
    .then(projectId => findOrCreateTutorial(organization, gameId, projectId))
    .then(projectId => navigateToTutorialReview(projectId, locale));
