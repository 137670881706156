const versionedTranslationType = 'tr1';

export function generateUuid() {
  var d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); //use high-precision timer if available
  }
  /*eslint-disable no-bitwise */
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);

    if (c === 'x') {
      return r.toString(16);
    }

    const op1 = r & 0x3;
    return (op1 | 0x8).toString(16)
  });
  /*eslint no-bitwise: "error"*/
}

export function isUuid(uuid) {
  const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
  return uuidPattern.test(uuid);
}

/**
 * Creates a TranslatableObject.
 * @param {string} text the default text to use for the TO if no translation occurs
 * @param {object} context a set of user definable parameters to identify where in the
 *                         application the translation occurs
 * @param {string} locale locale for the TO, defaults to en-US
 * @param {string} tuuid a uuid. If not provided, one will be generated by this function
 * @returns {TOObject} Returns a new Translatable Object
 */
export function createTO(text, context = {}, locale = 'en-US', tuuid = generateUuid()) {
  if (!isUuid(tuuid)) {
    console.warn(`Invalid tuuid: '${tuuid}'`);
  }

  const toObject = {
    _type: versionedTranslationType,
    locale,
    text,
    tuuid,
  };

  if (context) {
    toObject.context = context;
  }

  return toObject;
}

export function isTO(possibleTO) {
  return (
    possibleTO &&
    typeof possibleTO === 'object' &&
    typeof possibleTO._type !== 'undefined' &&
    typeof possibleTO.text !== 'undefined' &&
    typeof possibleTO.tuuid !== 'undefined'
  );
}

export function getStringFromTO(item) {

  if (typeof item === 'string') {
    try {
      const convertedItem = JSON.parse(item);
      return processTO(convertedItem);
    } catch (e) {
      return item;
    }
  } else if (typeof item === 'object') {
    return processTO(item);
  }

  return item;
}

export function getObjectFromTOJson(item) {
  try {
    const itemObject = JSON.parse(item);
    if (isTO(itemObject)) {
      return itemObject;
    }
    return {};
  } catch (e) {
    return {};
  }
}

function processTO(translatableObject) {

  if (typeof translatableObject.text !== 'undefined') {
    return translatableObject.text;
  }
  console.warn('Invalid Translatable Object: ');
  console.log(translatableObject);

  return translatableObject;
}
