import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {getStringFromTO} from '../../common/utils/i18nUtils';

import {Row, Title} from '../StyledComponents';

export const Image = styled.img`
  max-width: 100px;
  max-height: 100px;
  width: auto;
  height: auto;
  margin: auto 10px;
`;

export const DefaultImage = 'https://via.placeholder.com/100x100?text=No image set';

const mapStateToProps = store => ({
  games: store.db.games,
});

const GameDetails = ({children, gameId, games}) => {
  const game = games.gameLookup[gameId] || {};
  const {assets, tagline, title} = game;

  return (
    <Row>
      <Image
        alt="Game Art"
        name="boxart"
        src={assets?.boxart?.download || DefaultImage}
      />
      <Title>
        <h1>{getStringFromTO(title)}</h1>
        <h2>{getStringFromTO(tagline)}</h2>
      </Title>
      {children}
    </Row>
  );
};

GameDetails.propTypes = {
  children: PropTypes.node,
  gameId: PropTypes.string.isRequired,
  games: PropTypes.shape({
    gameLookup: PropTypes.object.isRequired,
  }).isRequired,
};

export default connect(
  mapStateToProps,
)(GameDetails);
