import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {COLOR, DropDown, Input, ModalConfirmation, Textarea} from '@dized/ui';

import {ProjectTypes, ProjectTypeNames} from '../../common/utils/projectUtils';

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.5em
`;

const Label = styled.div`
  display: flex;
  align-items: center;
`;

const MandatoryInput = styled(Input)`
  border-color: ${props => props.invalid ? COLOR.MESSAGE_RED : 'transparent'};
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
`;

export default class ProjectDetails extends Component {
  static propTypes = {
    /** when updating: descripition of the project */
    description: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
    /** when updating: status of the project */
    status: PropTypes.string,
    /** when updating: title of the project */
    title: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
    /** type of the project */
    type: PropTypes.string.isRequired,
    /** when creating: list of project types in game */
    types: PropTypes.arrayOf(PropTypes.string),
    /** when updating: project UUID */
    uuid: PropTypes.string,
    /** close/cancel callback */
    onClose: PropTypes.func.isRequired,
    /** create/update callback, receives state object */
    onConfirm: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    const {description, status, title, type, uuid} = this.props;
    this.state = {
      description: description?.text ?? '',
      status,
      title: title?.text ?? '',
      type,
      uuid,
    };
  }

  renderType() {
    const {type} = this.state;

    return (
      <>
        <Label>Type</Label>
        <Label>{ProjectTypeNames[type]}</Label>
      </>
    );
  }

  renderTypeChooser() {
    const {types} = this.props;
    const {type} = this.state;

    const allowedTypes = Object.values(ProjectTypes)
      .filter((type) => !( // filter out types for which no duplicates are allowed
        [
          ProjectTypes.RULES_CT,
          ProjectTypes.TUTORIAL_CT,
        ].includes(type) &&
        types.includes(type)
      ))
      .map((value) => ({label: ProjectTypeNames[value], value}));

    return (
      <>
        <Label>Type</Label>
        <DropDown
          onChange={e => this.setState({type: e.target.value})}
          options={[
            ...(type === '' ? [{label: 'Please select...', value: ''}] : []),
            ...allowedTypes,
          ]}
          value={type}
        />
      </>
    );
  }

  renderStatus() {
    const {status} = this.state;

    return (
      <>
        <Label>Status</Label>
        <DropDown
          onChange={e => this.setState({status: e.target.value})}
          options={[
            'unpublished',
            'published',
          ].map(value => ({label: value, value}))}
          value={status}
        />
      </>
    );
  }

  renderTitle() {
    const {title} = this.state;

    return (
      <>
        <Label>Name</Label>
        <MandatoryInput
          invalid={title.length === 0}
          placeholder="Enter project title..."
          onChange={e => this.setState({title: e.target.value})}
          value={title}
        />
      </>
    );
  }

  renderDescription() {
    const {description} = this.state;

    return (
      <>
        <Label>Description</Label>
        <Textarea
          placeholder="Enter project description..."
          onChange={e => this.setState({description: e.target.value})}
          value={description}
        />
      </>
    );
  }

  render() {
    const {onClose, onConfirm} = this.props;
    const {title, type, uuid} = this.state;
    const updating = !!uuid;
    const isValid = type && title.length > 0;

    return (
      <ModalConfirmation
        customStyles={{
          height: 'fit-content',
          width: 'fit-content',
          minWidth: '50%',
        }}
        header={updating ? 'Update project' : 'Create new project'}
        labelConfirm={updating ? 'Update' : 'Create'}
        onCancel={onClose}
        onClose={onClose}
        onConfirm={() => isValid && onConfirm(this.state)}
        visible
      >
        <Container>
          {updating ? this.renderType() : this.renderTypeChooser()}
          {updating && this.renderStatus()}
          {this.renderTitle()}
          {this.renderDescription()}
        </Container>
      </ModalConfirmation>
    );
  }
}
