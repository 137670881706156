export const palette = [
  '#E62B2B',
  '#FF870D',
  '#FFC903',
  '#B8D009',
  '#7BAD35',
  '#168DCC',
  '#58A2C1',
  '#A1C0CE',
  '#BC8BB4',
  '#9074C4',
  '#A87E74',
  '#A5997D'
];
