export default {
  background: {
    tint1: '#F8F8F8',
    tint2: '#C6C6C6',
    grayTint: '#F4F4F4',
    overlay: '#FDA300',
    overlay2: '#FFFF00',
    yellowTint: '#FEF8E7',
    greenTint: '#F1FAF5',
    orangeTint: '#FDF8F3',
    redTint: '#FEF6F6',
    blueTint: '#F7F9FD',
    purpleTint: '#F8F7FC',
    tealTint: '#F1FBFC',
  },
  border: {
    default: '#E4E7EB',
    muted: '#EDF0F2',
  },
  text: {
    muted: '#B4B4B4',
    default: '#8D8D8D',
    dark: '#2B70AC',
    secondary: '#F39E1E',
    selected: '#1070CA',
    success: '#00783E',
    info: '#084B8A',
    danger: '#BF0E08',
    warning: '#95591E',
  },
  icon: {
    default: '#8D8D8D',
    secondary: '#F39E1E',
    muted: '#B4B4B4',
    dark: '#0d2b44',
    disabled: 'rgba(67, 90, 111, 0.3)',
    selected: '#0d2b44;',
    success: '#47B881',
    info: '#1070CA',
    danger: '#EC4C47',
    warning: '#D9822B',
  },
  intent: {
    none: '#00d0db',
    secondary: '#C6C6C6',
    success: '#00d455',
    warning: '#EC4C47',
    danger: '#D9822B',
  },
};
