import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {MACEditor} from '@dized/ui/dist/MACEditor';

const mapStateToProps = state => ({
  organization: state.db.organization,
});

const OrgAdmin = ({organization: {uuid}}) => (
  <MACEditor
    configAttributes={{
      organizationId: uuid,
    }}
    context="/org/"
  />
);

OrgAdmin.propTypes = {
  organization: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
};

export default connect(
  mapStateToProps
)(OrgAdmin);
