import './Portal.css';

import Menu from '../Menu';
import Unreachable from '../Unreachable';
import Register from '../Register';
import TestOrganization from '../TestOrganization';

import SessionActions from '../../actions/SessionActions';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {Button} from '@dized/ui';

import Login from '../Login';

export const CREATE_TEST_ORGANIZATION_PATH = 'create-test-organization';
const REGISTER_PATH = 'register';

const mapStateToProps = store => ({
  invalidSession: store.session.invalidSession,
  cookiesAccepted: store.session.cookiesAccepted,
});

const mapDispatchToProps = dispatch => ({
  ...SessionActions.bindActions(dispatch),
});

class Portal extends Component {
  static propTypes = {
    cookiesAccepted: PropTypes.bool.isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }),
    invalidSession: PropTypes.bool.isRequired,
    COOKIES_ACCEPTED: PropTypes.func.isRequired,
    VALIDATE: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  componentDidMount() {
    this.props.VALIDATE(this);
  }

  openPrivacyPolicy() {
    window.open('https://dized.com/privacy-policy/', '_blank');
  }

  acceptCookie = () => {
    const {COOKIES_ACCEPTED} = this.props;
    return COOKIES_ACCEPTED();
  };

  renderToast() {
    const {cookiesAccepted} = this.props;
    if (cookiesAccepted) return;

    return (
      <div className="Portal-toast">
        <div className="Portal-toast-center">
          We use cookies to ensure the best experience in our applications.
          If you continue to use this application we will assume that you are happy with the policy.
          <div className="Portal-toast-container">
            <Button onClick={this.acceptCookie}>I Accept</Button>
            <Button onClick={this.openPrivacyPolicy}>Privacy Policy</Button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Portal">
        <div className="Portal-body">
          <div className="Portal-content">
            <Switch>
              <Route exact path="/" component={Unreachable} />
              <Route exact path={`/${CREATE_TEST_ORGANIZATION_PATH}`} component={TestOrganization} />
              <Route path={`/${REGISTER_PATH}`} component={Register} />
              <Route path="/:orgName/login" component={Login} />
              {/* NOTE: this must be last */}
              <Route path="/:orgName" component={Menu} />
            </Switch>
          </div>
        </div>
        {this.renderToast()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Portal);
