import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';

import {Button, COLOR, SideBarMenu, SideBarMenuContainer, SideBarMenuEntry, SideBarMenuGroup, SideBarMenuURL} from '@dized/ui';
import logo from '@dized/ui/assets/dized_logo.png';

import OrganizationActions from '../../actions/OrganizationActions';
import UserActions from '../../actions/UserActions';

import Games from '../Games';
import GameEditor from '../GameEditor';
import GameInfo from '../GameInfo';
import OrgAdmin from '../OrgAdmin';
import ProjectInfo from '../ProjectInfo';

import './Menu.css';

const Content = styled.div`
  min-width: 500px;
  padding: 20px;
`;

const Header = styled.div`
  color: ${COLOR.LIGHT_TURQUOISE};
  padding-bottom: 1em;
  text-align: center;
`;

const mapStateToProps = store => ({
  invalidOrganization: store.db.organization.invalid,
  invalidSession: store.session.invalidSession,
  organization: store.db.organization,
});

const mapDispatchToProps = dispatch => ({
  ...OrganizationActions.bindActions(dispatch),
  ...UserActions.bindActions(dispatch),
});

class Menu extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    invalidOrganization: PropTypes.bool.isRequired,
    invalidSession: PropTypes.bool.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        orgName: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    organization: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    GET_ORGANIZATION: PropTypes.func.isRequired,
    LOGOUT: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  state = {
    errorMessage: '',
    errorTitle: '',
  };

  componentDidMount() {
    this.getOrganization();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.invalidSession !== this.props.invalidSession) {
      this.getOrganization();
    }
  }

  getOrganization() {
    const {invalidSession, match: {params}, GET_ORGANIZATION} = this.props;
    const {orgName} = params;

    return GET_ORGANIZATION(orgName)
      .then(() => {
        const {invalidOrganization} = this.props;
        if (invalidOrganization) throw new Error();
      })
      .catch(() => {
        if (orgName && !invalidSession) {
          this.setState({
            errorTitle: 'Invalid Organization',
            errorMessage: (
              <div>
                <p>
                  You have entered an incorrect URL above, or you do not have permission to access this organization.
                </p>
                <p>
                  The organization you entered is <strong>{orgName}</strong> which is invalid.
                </p>
                <p>
                  <em>Please enter the correct URL to continue using this application or contact Dized support.</em>
                </p>
              </div>
            ),
          });
        }
      });
  }

  handleLogoutClick = () => {
    const {LOGOUT} = this.props;
    return Promise.resolve()
      .then(() => new Promise(resolve => this.setState(
        {
          errorTitle: '',
          errorMessage: '',
        },
        resolve
      )))
      .then(LOGOUT);
  };

  renderModal() {
    const {invalidSession} = this.props;
    const {errorMessage, errorTitle} = this.state;
    if (!errorMessage) return;

    return (
      <div className="Menu-modal-background">
        <div className="Menu-modal">
          <h2 className="Menu-modal-title">{errorTitle}</h2>
          {errorMessage}
          {!invalidSession && (
            <div className="Menu-modal-button-container">
              <Button onClick={this.handleLogoutClick}>Logout</Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const {history: {push}, match: {params}, organization: {name}, LOGOUT} = this.props;
    const {orgName} = params;

    return (
      <SideBarMenuContainer>
        <SideBarMenu
          header={(
            <Header>
              <img src={logo} alt="Dized" />
              <div>{name}</div>
            </Header>
          )}
        >
          <SideBarMenuGroup title="Games">
            <SideBarMenuEntry
              path={`/${orgName}/games`}
              onClick={path => push(path)}
            >
              All games
            </SideBarMenuEntry>
            <SideBarMenuEntry
              path={`/${orgName}/game/new-game/edit`}
              onClick={path => push(path)}
            >
              Create new game
            </SideBarMenuEntry>
          </SideBarMenuGroup>
          <SideBarMenuGroup title="Organization">
            <SideBarMenuEntry
              path={`/${orgName}/admin`}
              onClick={path => push(path)}
            >
              Administration
            </SideBarMenuEntry>
          </SideBarMenuGroup>
          <SideBarMenuGroup title="Account">
            <SideBarMenuEntry
              onClick={LOGOUT}
              path=""
            >
              Logout
            </SideBarMenuEntry>
          </SideBarMenuGroup>
          <SideBarMenuGroup title="Documentation">
            <SideBarMenuURL url="http://create.dized.com/">Content Creator Guide</SideBarMenuURL>
          </SideBarMenuGroup>
        </SideBarMenu>
        <Content>
          <Switch>
            {/* NOTE: order more specific path -> less specific path is important */}
            <Route path="/:orgName/admin" component={OrgAdmin} />
            <Route path="/:orgName/games" component={Games} />
            <Route path="/:orgName/game/:id/edit" component={GameEditor} />
            <Route path="/:orgName/game/:id" component={GameInfo} />
            <Route path="/:orgName/project/:gameId/:projectId" component={ProjectInfo} />
            <Route path="/:orgName" component={Games} />
          </Switch>
          {this.renderModal()}
        </Content>
      </SideBarMenuContainer>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu);
