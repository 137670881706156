import React, {Component} from 'react';
import LazyLoad from 'react-lazyload';
import folderImage from '../styles/images/folder.png';
import {TextArea} from './input'
import Modal from './modal';

import {assetDownloadURL} from '../actions/assetActions';

export default class Item extends Component {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.state = {
      isRenaming : false,
      oldName : this.props.name,
      newName: this.props.name,
      modalHeader: '',
      action: ''
    }

    this.action = {
      rename: 'rename'
    };
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.name !== prevProps.name)  {
      this.setState({oldName: this.props.name, newName : this.props.name})
    }
  }

  onCancel = () => {
    this.resetName();
    this.onModalClose();
  };

  onClick(event) {
    this.props.onClick(event, this.props.type, this.props.uuid, this.props.selected);
  }

  onChange = (event) => {
    this.setState({newName: event.target.value})
  }

  onKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      this.onBlur(event);
    }
  }

  onBlur = (event) => {
    const isValueBlank = event.target.value.replace(/\s/g, '').length && event.target.value.length > 0;
    if (isValueBlank) {
      this.setState({newName: event.target.value})
      this.onRenameItem(event.target.value)
    } else {
      this.setState({isRenaming : false})
      this.resetName();
    }
  }

  resetName = () => {
    this.setState({newName : this.state.oldName})
  }

  startRename = () => {
    if (!this.state.isRenaming) {
      this.setState({isRenaming : true})
    }
  }

  onRenameItem = (newName) => {
    this.confirm = () => this.renameItem(newName);
    const type = this.props.type;
    this.setState({action: this.action.rename, modalHeader: 'Rename ' + type + ' to \'' + newName+ '\' ?'});
    this.props.actions.SHOW_MODAL(true);
  }

  renameItem = (value) => {
    this.props.renameItem(this.props.type, value, this.props.uuid);
    this.onModalClose();
  }

  onModalClose() {
    this.setState({isRenaming : false, action: ''})
    this.props.actions.SHOW_MODAL(false);
  }

  renderModal = () => {
    return (
      <React.Fragment>
        {this.state.action !== '' &&
        <Modal
          show={this.props.assets.modal.show}
          header={this.state.modalHeader}
          onCancel={this.onCancel}
          onConfirm={this.confirm}>
        </Modal>
        }
      </React.Fragment>
    )
  }

  render() {
    const {uuid, locale, returnAsset, parent, type, assetData, moveToFolder, selected, modified} = this.props;
    const color = selected ? '#e9eff0' : 'white';
    const size = encodeURI(`?max=true&size=[100,100]&cachebuster=${modified}`);

    if (assetData) {
      this.itemData = assetData.find((element) => {
        return element.locale === locale;
      });
    }

    const imageSrc = assetDownloadURL(uuid, locale, size);

    return (
      <div className={'explorer-item'} onClick={(event) => this.onClick(event)}>
        {
          type === 'folder'
            ? (
              <div className="item-container" style={{background: color}} onDoubleClick={() => moveToFolder(uuid, parent)}>
                <img className="item-folder" alt="" src={folderImage} />
              </div>
            )
            : (
              <div className="item-container" style={{background: color}} onDoubleClick={() => returnAsset(uuid, locale, this.itemData)}>
                <LazyLoad offset={100} overflow={true} height="51px">
                  <img className="item-image" alt="" src={imageSrc} />
                </LazyLoad>
              </div>
            )
        }
        <div className="item-text" style={{background: color}} onDoubleClick={this.startRename}>
          {!this.state.isRenaming
            ? this.state.newName
            : <TextArea className="text-area"
                value={this.state.newName}
                name="rename"
                maxLength={25}
                autoFocus={true}
                onKeyDown={this.onKeyPress}
                onBlur={this.onBlur}
                onChange={this.onChange}/>
          }
        </div>
        {this.renderModal()}
      </div>
    )
  }
}

