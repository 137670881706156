import SessionActions from '../actions/SessionActions';
import UserActions from '../actions/UserActions';

import cookie from 'react-cookies';
import moment from 'moment';

const initialState = {
  cookiesAccepted: !!cookie.load('cookies_accepted'), // convert to boolean
  invalidCredentials: false,
  invalidSession: true,
  session: {}
};

export default (store = initialState, action) => {
  switch (action.type) {
    case SessionActions.COOKIES_ACCEPTED.FULFILLED:
      cookie.save('cookies_accepted', 1, {
        expires: moment().add(10, 'years').toDate(),
      });

      return {
        ...store,
        cookiesAccepted: true,
      };
    case SessionActions.VALIDATE.FULFILLED:
      return {
        ...store,
        invalidSession: false,
        session: action.payload.data,
      };
    case SessionActions.VALIDATE.REJECTED:
      return {
        ...store,
        invalidSession: true,
      };

    case UserActions.LOGIN.PENDING:
      return {
        ...store,
        invalidCredentials: false,
      };
    case UserActions.LOGIN.FULFILLED:
      return {
        ...store,
        invalidCredentials: false,
        invalidSession: false,
        session: action.payload.data,
      };
    case UserActions.LOGIN.REJECTED:
      return {
        ...store,
        invalidCredentials: true,
      };
    case UserActions.LOGOUT.FULFILLED:
      return {
        ...store,
        invalidSession: true,
      }

    default:
      return store;
  }
}
