import {initActions} from '@dized/ui/dist/redux/ActionHelper';
import {filterByOrganizationId, filterByUUID, generateFilter} from '@dized/ui/dist/redux/FilterHelper';
import {post, get, put} from '@dized/ui/dist/redux/RequestHelper';

// NOTE: "id" property is a version counter, not an UUID
const filterByLatestVersion = () => generateFilter(undefined, {limit: 1, order: 'id DESC'});

const path = '/api/v1/ggl/games';

export default initActions('GameActions', {
  GET_GAMES: orgUuid => get(path, filterByOrganizationId(orgUuid)),
  GET_GAME: uuid => get(path, filterByUUID(uuid)),
  POST_GAME: params => post(path, params),
  PUT_GAME: params => put(path, params),
  GET_LATEST_REVISION: gameUuid => get(`${path}/${gameUuid}/versions`, filterByLatestVersion(gameUuid)),
  POST_REVISION: params => post(`${path}/${params.gameId}/revision`, params),
  PUT_REVISION: (params, id) => put(`${path}/revision/${id}`, params),
});
