import React, {useRef, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Button, Input, Toaster} from '@dized/ui';

import OrganizationActions from '../../actions/OrganizationActions';
import UserActions from '../../actions/UserActions';

import background from '../Login/dized-logo-background.svg';
import logo from '../Login/dized.svg';

const Background = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: url(${background}) no-repeat 50% 5% #E3FBFE;
  align-items: center;
`;

const Container = styled.div`
  width: 90%;
  min-width: 500px;
  margin: 10px auto;
  padding: 10px 40px 20px;
  background-color: white;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  text-align: center;
  padding: 40px;
`;

const Paragraph = styled.div`
  margin: 5px auto;
`;

const StyledButton = styled(Button)`
  border: solid;
  border-color: transparent;

  &:focus {
    border-color: black;
  }
`;

const StyledInput = styled(Input)`
  border: solid;
  border-color: transparent;

  &:focus {
    border-color: black;
  }
`;

const changeFocus = (event, ref) => {
  if (event.target.value !== '' && event.key === 'Enter') ref.current.focus();
}

const mapStateToProps = store => ({
  invalidSession: store.session.invalidSession,
});

const mapDispatchToProps = dispatch => ({
  ...OrganizationActions.bindActions(dispatch),
  ...UserActions.bindActions(dispatch),
});

const TestOrganization = ({history: {replace}, invalidSession, CREATE_TEST_ORGANIZATION, LOGOUT}) => {
  const [name, setName] = useState('');
  const buttonRef = useRef();

  return (
    <Background>
      <Container>
        <Title>
          <img src={logo} alt="Dized"/>
          <h2>Create Test Organization</h2>
        </Title>
        <Paragraph>
          Please enter the name for your organization
        </Paragraph>
        <Paragraph>
          <StyledInput
            autoFocus
            onChange={e => setName(e.target.value)}
            onKeyPress={event => changeFocus(event, buttonRef)}
            value={name}
          />
        </Paragraph>
        <StyledButton
          disabled={name.length === 0}
          onClick={() => {
            return CREATE_TEST_ORGANIZATION(name)
              .then(result => {
                Toaster.success(`New test organization '${name}' has been created`);

                // NOTE: the current session doesn't have the new access rights yet.
                //       Hence we need to logout and then redirect to the new organization.
                const {organization} = result.value.data;
                return Promise.resolve()
                  .then(() => {
                    if (!invalidSession) return LOGOUT();
                  })
                  .then(() => replace(`/${organization.slug}`));
              })
              .catch(error => {
                if (error.response)
                  Toaster.error(error.response.data.error.message);
                else if (error.message)
                  Toaster.error(error.message);
                else
                  Toaster.error('Something went wrong.');
              });
          }}
          ref={buttonRef}
        >
          Create
        </StyledButton>
      </Container>
    </Background>
  );
};

TestOrganization.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  invalidSession: PropTypes.bool.isRequired,
  CREATE_TEST_ORGANIZATION: PropTypes.func.isRequired,
  LOGOUT: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestOrganization);
