import {initActions} from '@dized/ui/dist/redux/ActionHelper';
import {filterByProjectId} from '@dized/ui/dist/redux/FilterHelper';
import {post, get} from '@dized/ui/dist/redux/RequestHelper';

const path = '/api/v1/rules/versions';

export default initActions('RuleActions', {
  GET_VERSION: uuid => get(path, filterByProjectId(uuid)),
  POST_VERSION: params => post(path, params),
});
