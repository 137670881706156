import moment from 'moment';

import ProjectActions from '../actions/ProjectActions';
import UserActions from '../actions/UserActions';

import {isTO, createTO} from '../common/utils/i18nUtils';

const initialState = {
  projects: [],
};

const addProject = p => {
  let {description, locale: [locale], projectImage = null, status, title} = p;

  // NOTE: for legacy projects description may be NULL
  if (!isTO(description))
    description = createTO(description ?? '', {}, locale);
  if (!isTO(title))
    title = createTO(title, {}, locale);

  // NOTE: for legacy projects status may be "draft"
  if (status === 'draft')
    status = 'unpublished';

  return {
    category: p.category,
    created: moment(p.created, moment.ISO_8601).unix(),
    description,
    projectImage,
    status,
    title,
    type: p.type,
    uuid: p.uuid,
  };
};

export default (store = initialState, action) => {
  const {payload} = action;

  switch (action.type) {
    case ProjectActions.GET_PROJECTS.FULFILLED:
      return {
        ...store,
        projects: payload.data.map(p => addProject(p)),
      };

    case ProjectActions.POST_PROJECT.FULFILLED:
      return {
        ...store,
        projects: [
          ...store.projects,
          addProject(payload.data),
        ],
      };

    case ProjectActions.PATCH_PROJECT.FULFILLED: {
      const {data} = payload;
      const {uuid} = data;

      return {
        ...store,
        projects: store.projects.map(p => p.uuid === uuid ? addProject(data) : p),
      };
    }

    case UserActions.LOGOUT.FULFILLED:
      return {...initialState}; // reset state on logout

    default:
      return store;
  }
}
