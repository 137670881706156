import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'

import sessionReducer from './SessionReducer';
import gameReducer from './GameReducer';
import organizationReducer from './OrganizationReducer';
import projectReducer from './ProjectReducer';
import shellReducer from './ShellReducer';
import assetUiReducer from '../components/AssetUI/reducers/asset_ui_reducer';

export default history => ({
  session: sessionReducer,
  db: combineReducers({
    organization: organizationReducer,
    games: gameReducer,
    projects: projectReducer,
  }),
  router: connectRouter(history),
  shell: shellReducer,
  assets: combineReducers({
    assetui: assetUiReducer
  })
});
