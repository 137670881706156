import {initActions} from '@dized/ui/dist/redux/ActionHelper';
import {generateFilter} from '@dized/ui/dist/redux/FilterHelper';
import {get} from '@dized/ui/dist/redux/RequestHelper';

const filterBySlug = ilike => ilike ? generateFilter({slug: {ilike}}) : undefined;

const path = '/api/v1/user/Organizations';

export default initActions('OrganizationActions', {
  GET_ORGANIZATION: slug => get(path, filterBySlug(slug)),
});
