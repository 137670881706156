import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Prompt} from 'react-router'
import {connect} from 'react-redux';
import {Button, Input, Textarea, DropDown} from '@dized/ui';
import {limits} from '../../consts'
import './GameEditor.css';
import GameActions from '../../actions/GameActions';
import ShellActions from '../../actions/ShellActions';
import RuleActions from '../../actions/RuleActions';
import ProjectActions from '../../actions/ProjectActions';
import {getStringFromTO, isTO, createTO, getObjectFromTOJson} from '../../common/utils/i18nUtils';
import AssetUI from '../../components/AssetUI';
import {createProject, createRuleVersion, createGameVersion} from '../../common/rulesHelpers/entityUtils';
import {ProjectTypes} from '../../common/utils/projectUtils';

const mapStateToProps = store => ({
  games: store.db.games,
  organization: store.db.organization,
  assetExplorerItem: store.shell.assetExplorerItem
});

const mapDispatchToProps = dispatch => ({
  ...ShellActions.bindActions(dispatch),
  ...GameActions.bindActions(dispatch),
  ...ProjectActions.bindActions(dispatch),
  ...RuleActions.bindActions(dispatch),
});

function convertToArray(fieldValue) {
  let removeSpaces = /\s*,\s*/;
  return fieldValue.split(removeSpaces).map((value) => (value));
}

function createLookup(array) {
  let lookup = {};

  array.forEach((translatableObjectString) => {
    let to = getObjectFromTOJson(translatableObjectString);
    lookup[to.text] = to;
  });

  return lookup;
}

function lookupTO(lookupObject, searchValue) {

  if (lookupObject[searchValue]) {
    return lookupObject[searchValue];
  }

  return {};
}

function updateAndStringifyTO(possibleTO, newText, locale) {
  if (isTO(possibleTO)) {
    possibleTO.text = newText;
    if (!possibleTO.locale) possibleTO.locale = locale;
    return JSON.stringify(possibleTO);
  }

  const translationContext = {
    'application': 'org-portal',
    'description': 'game attribute'
  };

  return JSON.stringify(createTO(newText, translationContext, locale));
}

function onPreventUnload(event) {
  // Cancel the event as stated by the standard.
  event.preventDefault();
  // Chrome requires returnValue to be set.
  event.returnValue = '';
}

class GameEditor extends Component {
  static propTypes = {
    DISMISS_ASSET_EXPLORER: PropTypes.func.isRequired,
    GET_GAME: PropTypes.func.isRequired,
    GET_LATEST_REVISION: PropTypes.func.isRequired,
    POST_GAME: PropTypes.func.isRequired,
    POST_PROJECT: PropTypes.func.isRequired,
    POST_REVISION: PropTypes.func.isRequired,
    POST_VERSION: PropTypes.func.isRequired,
    PUT_GAME: PropTypes.func.isRequired,
    PUT_REVISION: PropTypes.func.isRequired,
    SHOW_ASSET_EXPLORER: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.preventCloseAndReload();
    this.defaultImage = 'https://via.placeholder.com/250x250?text=Click to set image';
    this.changesOccured = false;

    this.state = {
      title: 'New Game',
      tagline: 'Enter Tagline',
      main: '',
      player_min: '1',
      player_max: '15',
      age_min: '0',
      age_max: '18',
      playing_time: '1',
      locales: ['en-US'],
      homepage: '',
      status: 'unpublished',
      organizationId: '',
      categories: [1, 2],
      publishers: '',
      designers: '',
      artists: '',
      awards: '',
      assets: {
        boxart: {
          download: this.defaultImage
        },
        coverimage: {
          download: this.defaultImage
        }
      },
      saveButtonDisabled: false
    };

    this.translatableObjects = {
      title: {},
      tagline: {},
      main: {},
      homepage: {},
      publishers: {},
      designers: {},
      artists: {},
      awards: {}
    }
  }

  componentDidMount() {
    const {match, organization, GET_GAME, GET_LATEST_REVISION} = this.props;
    const gameId = match.params.id;

    if (gameId !== 'new-game') {
      GET_GAME(gameId)
        .then(args => {
          GET_LATEST_REVISION(gameId);
        });
    }

    if (gameId === 'new-game' && organization.name !== '') {
      this.setState({
        publishers: organization.name,
        organizationId: organization.uuid
      });
    }
    document.title = "Dized Portal: Game Editor"
  }

  componentDidUpdate(oldProps) {
    const {games, match, organization} = this.props;
    const gameId = match.params.id;
    const gameData = games.gameLookup[gameId];
    const oldGameData = oldProps.games.gameLookup[gameId]

    if (gameId === 'new-game' && oldProps.organization !== organization) {
      this.setState({
        publishers: organization.name,
        organizationId: organization.uuid
      });
    }

    if (gameData && oldGameData !== gameData && gameData.id) {
      this.applyDaoToState(gameData);
    }
  }

  preventCloseAndReload() {
    window.addEventListener('beforeunload', onPreventUnload);
  }

  allowCloseAndReload() {
    window.removeEventListener('beforeunload', onPreventUnload);
  }

  applyDaoToState(gameDao) {
    let gameData = Object.assign({}, gameDao);

    this.translatableObjects = {
      title: getObjectFromTOJson(gameDao.title),
      tagline: getObjectFromTOJson(gameDao.tagline),
      main: getObjectFromTOJson(gameDao.main),
      homepage: getObjectFromTOJson(gameDao.homepage),
      publishers: createLookup(gameDao.publishers),
      designers: createLookup(gameDao.designers),
      artists: createLookup(gameDao.artists),
      awards: createLookup(gameDao.awards)
    }

    gameData.status = getStringFromTO(gameDao.status);
    gameData.title = getStringFromTO(gameDao.title);
    gameData.tagline = getStringFromTO(gameDao.tagline);
    gameData.main = getStringFromTO(gameDao.main);
    gameData.homepage = getStringFromTO(gameDao.homepage);
    gameData.publishers = gameDao.publishers.map((publisher) => getStringFromTO(publisher)).join(', ');
    gameData.designers = gameDao.designers.map((designer) => getStringFromTO(designer)).join(', ');
    gameData.artists = gameDao.artists.map((artist) => getStringFromTO(artist)).join(', ');
    gameData.awards = gameDao.awards.map((award) => getStringFromTO(award)).join(', ');

    this.setState(gameData);
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name.toString();
    let value = target.value;

    if (target.type === 'number') {

      let max = name === 'playing_time' ? 999 : 99;
      max = name.includes('age') ? 18 : max;
      max = name.includes('player') ? 1000 : max;

      let min = name === 'playing_time' ? 1 : 0;
      min = name.includes('player') ? 1 : min;
      min = name.includes('age') ? 0 : min;

      if (value > max || value < min) return;
      if (!value) return;

      if (value.toString().charAt(0) === '0') {
        this.setState({[name]: '0'});
        return;
      }
    }

    this.setState({
      [name]: value
    });

    this.changesOccured = true;
  }

  handleSelectChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = [target.value];

    this.setState({
      [name]: value
    });

    this.changesOccured = true;
  }

  invalidValues() {
    const {player_max, player_min, age_max, age_min} = this.state;
    const invalidFields = [];

    if (player_max < player_min) {
      invalidFields.push('Max players cannot be smaller than the minimum');
    }

    // age_max === 0 means "<age_min>+" and is therefore valid
    if (age_max > 0 && age_max < age_min) {
      invalidFields.push('Max age cannot be smaller than the minimum');
    }

    return invalidFields.join(', ');
  }

  missingMandatoryFields() {
    const {title, tagline, main} = this.state;
    const fields = [
      {name: 'title', value: title},
      {name: 'tagline', value: tagline},
      {name: 'main', value: main}
    ];

    const missingFields = [];

    fields.forEach(currentField => {
      if (currentField.value === '') {
        missingFields.push(currentField.name);
      }
    });

    return missingFields.join(', ');
  }

  handleSave = () => {
    const {
      match, POST_GAME, POST_PROJECT, POST_VERSION,
      PUT_GAME, organization, POST_REVISION,
      PUT_REVISION
    } = this.props;
    const {locales: [locale]} = this.state;

    const emptyFields = this.missingMandatoryFields();
    if (emptyFields !== '') {
      alert(`The following field(s) are mandatory: ${emptyFields}`);
      return;
    }

    const invalidValues = this.invalidValues();
    if (invalidValues !== '') {
      alert(`The following value(s) are invalid: ${invalidValues}`);
      return;
    }

    const gameId = match.params.id;

    let gameDao = Object.assign({}, this.state);

    gameDao.title = updateAndStringifyTO(this.translatableObjects.title, gameDao.title, locale);
    gameDao.tagline = updateAndStringifyTO(this.translatableObjects.tagline, gameDao.tagline, locale);
    gameDao.main = updateAndStringifyTO(this.translatableObjects.main, gameDao.main, locale);
    gameDao.homepage = updateAndStringifyTO(this.translatableObjects.homepage, gameDao.homepage, locale);

    gameDao.publishers = convertToArray(gameDao.publishers).map((publisher) =>
      updateAndStringifyTO(lookupTO(this.translatableObjects.publishers, publisher), publisher, locale)
    );
    gameDao.designers = convertToArray(gameDao.designers).map((designer) =>
      updateAndStringifyTO(lookupTO(this.translatableObjects.designers, designer), designer, locale)
    );
    gameDao.artists = convertToArray(gameDao.artists).map((artist) =>
      updateAndStringifyTO(lookupTO(this.translatableObjects.artists, artist), artist, locale)
    );
    gameDao.awards = convertToArray(gameDao.awards).map((award) =>
      updateAndStringifyTO(lookupTO(this.translatableObjects.awards, award), award, locale)
    );

    gameDao.organizationId = organization.uuid;
    let context = this;

    this.setState({saveButtonDisabled: true});

    // TODO: Make this a little more readable
    if (gameId === 'new-game') {
      POST_GAME(gameDao)
        .then((args) => {
          gameDao.gameId = args.action.payload.data.uuid;
          POST_REVISION(createGameVersion(gameDao))
            .then((args) => {
              POST_PROJECT(createProject(
                {
                  uuid: args.action.payload.data.gameId,
                  title: 'First Edition',
                  organizationId: organization.uuid,
                  type: ProjectTypes.RULES_CT
                }))
                .then((args) => {
                  POST_VERSION(createRuleVersion(
                    {
                      uuid: args.action.payload.data.uuid,
                      title: 'v1.0'
                    }))
                    .then((args) => {
                      context.navigateToGamesPage();
                    });
                });
            })
        });
    } else {
      PUT_GAME(gameDao)
        .then((args) => {
          const versionId = gameDao.versionId;
          PUT_REVISION(createGameVersion(gameDao), versionId)
            .then(() => {
              context.navigateToGamesPage();
            })
        });
    }

    this.changesOccured = false;
  }

  navigateToGamesPage() {
    const {history} = this.props;
    this.changesOccured = false;
    this.allowCloseAndReload();
    history.goBack();
  }

  handleCancel = () => {
    if (this.changesOccured) {
      if (window.confirm('You have unsaved changed, do you really want to leave this page?')) {
        this.navigateToGamesPage();
      }
    } else {
      this.navigateToGamesPage();
    }
  }

  handleFocus(event) {
    // When a text input is focused, select all text
    event.preventDefault();
    event.target.select();
  }

  handleImageClick = (event) => {
    this.props.SHOW_ASSET_EXPLORER(event.target.name);
  }

  handleBoxArtSelect = (asset) => {
    this.changesOccured = true;
    this.setState({assets: {...this.state.assets, boxart: asset}});
  }

  handleCoverImageSelect = (asset) => {
    this.changesOccured = true;
    this.setState({assets: {...this.state.assets, coverimage: asset}});
  }

  handleAssetModalClose = (event) => {
    this.props.DISMISS_ASSET_EXPLORER();
  }

  getCharLimit = (name) => {
    switch (name) {
      case 'title':
        return limits.MAX_GAME_TITLE;
      case 'tagline':
        return limits.MAX_GAME_TAGLINE;
      case 'main':
        return limits.MAX_GAME_DESC;
      case 'publishers':
        return limits.MAX_GAME_PUBLISHER;
      default:
        return 1000;
    }
  }

  renderInput = (name, className = 'GameEditor-long-text', inputType = 'text') => {
    return (
      <Input name={name} className={className} onChange={this.handleInputChange}
        value={this.state[name]} onFocus={this.handleFocus} type={inputType} maxLength={this.getCharLimit(name)}/>
    )
  }

  renderState = () => {
    const options = [
      {label: 'Published', value: 'published'},
      {label: 'Unpublished', value:'unpublished'}
    ];
    return (
      <DropDown name='status' className='GameEditor-long-text' options={options}
        value={this.state['status']} onChange={this.handleInputChange}/>
    )
  }

  renderTextArea = (name, placeholder) => {
    return (
      <Textarea name={name} className="GameEditor-long-text" onChange={this.handleInputChange}
        value={this.state[name]} placeholder={placeholder} maxLength={this.getCharLimit(name)}/>
    )
  }


  renderForm() {
    const {renderInput, renderTextArea, renderState} = this;

    return (
      <React.Fragment>
        <label>Game State * {renderState()}</label>
        <label>Game Title * {renderInput('title')}</label>
        <label>Game Tagline * {renderInput('tagline')}</label>
        <label>Game Description * {renderTextArea('main', 'Enter Description')}</label>
        <label>
          Game Information
          <div className="GameEditor-section">
            <div className="GameEditor-section-column">
              <label className="GameEditor-section-column-left">Players</label>
              {renderInput('player_min', 'GameEditor-short-text', 'number')}
              <label className="GameEditor-section-column-center">-</label>
              {renderInput('player_max', 'GameEditor-short-text', 'number')}
            </div>
            <div className="GameEditor-section-column">
              <label className="GameEditor-section-column-left">Recommended Age</label>
              {renderInput('age_min', 'GameEditor-short-text', 'number')}
              <label className="GameEditor-section-column-center">-</label>
              {renderInput('age_max', 'GameEditor-short-text', 'number')}
            </div>
            <div className="GameEditor-section-column">
              <label className="GameEditor-section-column-left">Playing Time (minutes)</label>
              {renderInput('playing_time', 'GameEditor-short-text', 'number')}
            </div>
          </div>
        </label>
        <label>Publisher {renderInput('publishers')}</label>
        <label>Product Page URL {renderInput('homepage')}</label>
        <label>(* required fields)</label>
      </React.Fragment>
    );
  }

  messageIfDataChanged = () => {
    return this.changesOccured ? 'You have unsaved changes, are you sure you want to leave?' : true;
  }

  render() {
    const attr = {
      mimeType: 'image/png, image/jpeg',
      locale: 'en-US',
      size: '[2048,2048]',
      min: true,
      max: true
    };

    const {title, tagline, assets, organizationId} = this.state;
    const {assetExplorerItem} = this.props;

    const boxartSrc = (assets.boxart && assets.boxart.download) || this.defaultImage;
    const coverimageSrc = (assets.coverimage && assets.coverimage.download) || this.defaultImage;

    const handleImageSelected = assetExplorerItem === 'boxart'
      ? this.handleBoxArtSelect
      : this.handleCoverImageSelect;

    return (
      <div className="GameEditor">
        <div className="GameEditor-title-container">
          <h1>{title}</h1>
          <h2>{tagline}</h2>
        </div>
        <div className="GameEditor-form-container">
          <div className="GameEditor-left-column">
            <h3>Menu Image</h3>
            <label className="GameEditor-smallprint">
            Minimum size: 1024x1024 pixels<br/>
            Recommended size: 2048x2048 pixels<br/>
            <i>Square image representing your game.</i>
            </label>
            <img alt="Game Art" src={boxartSrc}
              name="boxart" onClick={this.handleImageClick} />
            <h3>Background Image</h3>
            <label className="GameEditor-smallprint">
            Minimum size: 1024x512 pixels<br/>
            Recommended size: 2048x1024 pixels<br/>
            <i>Background image on your game's page.</i>
            </label>
            <img alt="Cover Art" src={coverimageSrc}
              name="coverimage" onClick={this.handleImageClick} />
          </div>
          <div className="GameEditor-right-column">
            {this.renderForm()}
            <div className="GameEditor-button-container">
              <Button onClick={this.handleCancel}>Cancel</Button>
              <Button onClick={this.handleSave} disabled={this.state.saveButtonDisabled}>Save</Button>
            </div>
          </div>
        </div>
        <AssetUI show={assetExplorerItem !== ''}
          attr={attr}
          callBack={handleImageSelected}
          close={this.handleAssetModalClose}
          organizationId={organizationId} />
        <Prompt
          message={this.messageIfDataChanged}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameEditor);
