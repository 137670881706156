import React, {Component} from 'react';
import propTypes from 'prop-types';
import {Button} from '@dized/ui';

export default class Status extends Component {

  static propTypes = {
    statusMessage: propTypes.string,
    errorList: propTypes.array,
    dismissErrors: propTypes.func,
    confirmOverwrite: propTypes.shape({
      filename: propTypes.string,
      callback: propTypes.func
    })
  }

  handleOverwriteClick = (event) => {
    this.props.confirmOverwrite.callback(event.target.name === 'yes');
  }

  renderOverwrite() {
    const {confirmOverwrite} = this.props;

    if (confirmOverwrite !== null) {
      return (
        <div className="status-message">
          Overwrite file: <strong>{confirmOverwrite.filename}</strong>?
          <div className="button-container">
            <Button name="yes" onClick={this.handleOverwriteClick}>Yes</Button>
            <span> </span>
            <Button name="no" onClick={this.handleOverwriteClick}>No</Button>
          </div>
        </div>
      );
    }
    return null;
  }

  renderStatusMessage() {
    const {confirmOverwrite, statusMessage} = this.props;

    if (confirmOverwrite) {
      return null;
    }

    if (statusMessage) {
      return (
        <div className="status-message">
          {statusMessage}
        </div>
      );
    }
    return null;
  }

  renderErrorList() {
    const {confirmOverwrite, errorList, dismissErrors, statusMessage} = this.props;

    if (confirmOverwrite || statusMessage) {
      return null;
    }

    if (errorList && errorList.length > 0) {
      return (
        <div className="error-container">
          The following errors occurred:
          <table className="error-table">
            <thead>
              <tr>
                <th>File Name</th>
                <th>Error</th>
              </tr>
            </thead>
            <tbody>
              {
                errorList.map((error, i) => {
                  return (
                    <tr key={i}>
                      <td>{error.file}</td>
                      <td>{error.message}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>

          <Button onClick={dismissErrors}>OK</Button>
        </div>
      );
    }

    return null;
  }

  render() {
    const {confirmOverwrite, errorList, statusMessage} = this.props;

    let classes = ['status-message-container'];

    if (confirmOverwrite !== null || statusMessage || (errorList && errorList.length > 0)) {
      classes.push('show');
    }

    return (
      <div className={classes.join(' ')}>
        {this.renderOverwrite()}
        {this.renderStatusMessage()}
        {this.renderErrorList()}
      </div>
    )

  }
}
