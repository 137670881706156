import React, {Component} from 'react';
import propTypes from 'prop-types';
import {Button} from '@dized/ui';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

const customModalStyles = {
  height: 'auto',
  bottom: 'auto',
  top: '50%',
  transform: 'translateY(-50%)'
};

export default class Modal extends Component {
    static propTypes = {
      show: propTypes.bool,
      header: propTypes.string,
      onConfirm: propTypes.func,
      onCancel: propTypes.func.isRequired
    };

    static defaultProps = {
      show: false
    };

    render() {
      const {show, header, children, onConfirm, onCancel} = this.props;
      return (
        <Rodal visible={show} onClose={onCancel} customStyles={customModalStyles}>
          <div className="modal">
            <div className="modal__header">{header}</div>
            <div className="modal__text">{children}</div>
            <div className="modal__wrapper">
              <div className="modal__button">
                <Button onClick={() => onCancel()}>Cancel</Button>
              </div>
              {onConfirm &&
                            <div className="modal__button">
                              <Button form='filterForm' value='submit' onClick={(e) => onConfirm(e)} secondary>Confirm</Button>
                            </div>}
            </div>
          </div>
        </Rodal>
      )
    }
}
