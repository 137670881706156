import React, {Component} from 'react'
import Flag from 'react-flagkit'
import Modal from './modal';
import {Icon, Input} from '@dized/ui';
import {BeatLoader} from 'react-spinners'

import {assetDownloadURL} from '../actions/assetActions';

export default class Details extends Component {

  constructor(props) {
    super(props);
    this.input = React.createRef();

    this.state = {
      modalHeader: '',
      action: '',
      newName: '',
      oldName:'',
      imageIsLoading: true
    }

    this.action = {
      rename: 'rename'
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.meta.name !== prevProps.meta.name) {
      this.setState({newName: this.props.meta.name, oldName: this.props.meta.name, imageIsLoading: true})
    }
  }

  onCancel = () => {
    this.resetName();
    this.onModalClose();
  };

  onChange = (event) => {
    this.setState({newName: event.target.value})
  }

  onKeyPress = (event) => {
    if (event.keyCode === 13) {
      this.onBlur(event);
    }
  }

  onBlur = (event) => {
    const valueIsNotBlank = (event.target.value.replace(/\s/g, '').length && event.target.value.length > 0);
    if (valueIsNotBlank && event.target.value !== this.state.oldName) {
      this.setState({newName : event.target.value})
      this.onRenameItem(event.target.value)
    } else {
      this.resetName();
    }
  }

  resetName = () => {
    this.setState({newName : this.state.oldName})
  }

  getAssetLocales = (meta, assetData) => {
    let locales = '';
    assetData && assetData.forEach((item) => {
      locales = locales + item['locale'] + ' '
    });
    return locales;
  };

  getAssetSize = (meta, assetData) => {
    let size = 0;
    assetData && assetData.forEach((item) => {
      size = item['locale'] === meta['locale'] && item['size']
    });

    size = size / 1024;
    size = size / 1024;
    return size.toFixed(2);
  };

  getAssetRes = (meta, assetData) => {
    let data = {};
    assetData && assetData.forEach((item) => {
      data = item['locale'] === meta['locale'] && item
    });

    let size = data.meta && data.meta.size && data.meta.size;
    return size && size[0] + ' x ' + size[1]
  };

  getAssetType = (meta, assetData) => {
    let data = {};
    assetData && assetData.forEach((item) => {
      data = item['locale'] === meta['locale'] && item
    });
    return data && data['type']
  };

  onRenameItem = (newName) => {
    this.confirm = () => this.renameItem(newName);
    const type = this.props.selectedItem.type;
    this.setState({action: this.action.rename, modalHeader: 'Rename ' + type + ' to \'' + newName+ '\' ?'});
    this.props.actions.SHOW_MODAL(true);
  }

  renameItem = (value) => {
    this.props.renameItem(this.props.selectedItem.type, value, this.props.selectedItem.uuid);
    this.onModalClose();
  }

  onModalClose() {
    this.setState({action: ''})
    this.props.actions.SHOW_MODAL(false);
  }

  renderModal = () => {
    return (
      <React.Fragment>
        {this.state.action !== '' &&
        <Modal
          show={this.props.assets.modal.show}
          header={this.state.modalHeader}
          onCancel={this.onCancel}
          onConfirm={this.confirm}>
        </Modal>
        }
      </React.Fragment>
    )
  }

  renderMeta() {
    const {meta, assetData} = this.props;
    const data = assetData && assetData.assetData;
    return (
      <div className="details-data">
        <div className='details-data-input'>
          <Icon color="#000" size='35px'>
            {this.props.selectedItem.type === 'folder'
              ? 'folder'
              : 'insert_drive_file'}
          </Icon>
          {this.renderMetaNameInput(meta)}
        </div>
        {this.renderMetaDetails(meta, data)}
      </div>
    )
  }

  renderMetaNameInput = (meta) => {
    return (meta &&
      <Input
        ref={this.input}
        className={'details-data-name'}
        value={this.state.newName || ''}
        maxLength={25}
        onKeyDown={this.onKeyPress}
        onBlur={this.onBlur}
        onChange={this.onChange}/>
    )
  }

  renderMetaDetails = (meta, data) => {
    return (meta &&
      <div className='details-data-meta'>
        <ul>
          <li>{meta['locale'] && 'Locale: ' + meta['locale']}</li>
          {data && (<li>Locales: {this.getAssetLocales(meta, data)}</li>)}
          {data && (<li>Type: {this.getAssetType(meta, data)}</li>)}
          {data && (<li>Size: {this.getAssetSize(meta, data)}MB</li>)}
          {data && (<li>Resolution: {this.getAssetRes(meta, data)}</li>)}
          <li>Created: {meta['created'] && new Date(meta['created']).toLocaleDateString() + ': ' + new Date(meta['created']).toLocaleTimeString()}</li>
          <li>Modified: {meta['modified'] && new Date(meta['modified']).toLocaleDateString() + ': ' + new Date(meta['modified']).toLocaleTimeString()}</li>
        </ul>
      </div>
    )
  }

  onImageLoad = () => {
    this.setState({imageIsLoading : false})
  }

  renderFileMeta() {
    const {meta, selectedItem, assetData, returnAsset} = this.props;
    const size = encodeURI('?max=true&size=[250,250]');

    return (
      Object.keys(meta).length > 0 ?
        <div className="details-container">
          <div className="details-thumbnail">
            {this.state.imageIsLoading && this.renderLoader()}
            <img className="details-image" alt="" onLoad={this.onImageLoad} onError={this.onImageLoad}
              src={assetDownloadURL(meta.id, meta.locale, size)}/>
          </div>
          <div className="details-locales">
            {meta.locales && meta.locales.map(function (item, i) {
              return <div key={i} className={'details-locales-flag'}>
                <Flag country={item.substring(3, 5)} size={45}/>
              </div>
            })
            }
          </div>
          {this.renderMeta()}
          {this.renderModal()}
          <button className='details-button details-button-select' onClick={() =>
            returnAsset(selectedItem.uuid, meta.locale, assetData)}>
            Select
          </button>
        </div> : <div className="details-container"/>
    )
  }

  renderFolderMeta() {
    const {meta, selectedItem} = this.props;
    return (
      Object.keys(meta).length > 0 ?
        <div className="details-container">
          <div className="details-thumbnail">
            <div className="details-folder"/>
          </div>
          {this.renderMeta(meta, selectedItem)}
          {this.renderModal()}
        </div> : <div className="details-container"/>
    )
  }

  renderLoader () {
    return (
      <div className="details-loader">
        <BeatLoader color="#0090a1"/>
      </div>
    )
  }

  render() {
    return (
      this.props.showLoader
        ? <div className="details-container">{this.renderLoader()}</div>
        : this.props.selectedItem
        && this.props.selectedItem.type === 'file'
          ? this.renderFileMeta()
          : this.renderFolderMeta()
    )
  }
}
