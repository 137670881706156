
import AssetActions from '../actions/assetActions';

const initialState = {
  folders: [],
  content: [],
  meta: {},
  currentAsset: {},
  last_accessed: undefined,
  modal: {
    show: false
  },
  typeFilter: ''
};

/*eslint complexity: ["error", 20]*/

export default function assetReducer(store = initialState, action) {

  switch (action.type) {
    case AssetActions.SHOW_MODAL.FULFILLED:
      return {...store, ...{modal: {show: action.payload.data}}};
    case AssetActions.RESET_CONTENT.FULFILLED:
      return {...store, ...{folders: [], content: []}};
    case AssetActions.SET_LAST_ACCESSED_FOLDER.FULFILLED:
      return {...store, ...{last_accessed: action.payload.data}};
    case AssetActions.FILTER_ASSETS.FULFILLED:
      return {...store, ...{typeFilter: action.payload.data}};
    case AssetActions.RESET_META.FULFILLED:
      return {...store, ...{meta: {}}};
    case AssetActions.CREATE_FOLDER.FULFILLED:
      return {...store, folders: [...store.folders, action.payload.data]};
    case AssetActions.DOWNLOAD_ASSET_LOCALE.FULFILLED:
      console.info(action.payload);
      return {...store, ...{previews: {[action.payload.marker]: action.payload.data}}};
    case AssetActions.GET_FOLDER_META.FULFILLED:
      return {...store, ...{meta: action.payload.data}};
    case AssetActions.GET_ASSET.FULFILLED:
      return {...store, ...{meta: action.payload.data}};
    case AssetActions.GET_CURRENT_ASSET.FULFILLED:
      return {...store, ...{currentAsset: action.payload.data}}
    case AssetActions.GET_FOLDERS.FULFILLED:
      return {...store, ...{folders: action.payload.data}};
    case AssetActions.GET_FOLDER_CONTENT.FULFILLED:
      return {...store, ...{content: action.payload.data}};
    case AssetActions.DELETE_ASSET.FULFILLED:
    case AssetActions.DELETE_FOLDER.FULFILLED:
    case AssetActions.UPLOAD_ASSET.FULFILLED:
    default:
      return store;
  }
}
