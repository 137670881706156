/**
 * Convert legacy Asset UI callback parameter to AO1 object
 *
 * @param {object} asset     - callback parameter from Asset UI
 * @param {object} [context] - AO1 context
 * @returns {object}           AO1 object
 */
export const assetToAO1 = (asset, context = {}) => {
  const {
    attr: {locale, size, type: mimeType},
    download: url,
    uuid: assetId
  } = asset;

  return {
    _type: 'ao1',
    assetId,
    attr: {},
    context,
    url,
    _meta: {
      aspectRatio: '1:1', // legacy Asset UI doesn't provide this
      locale,
      mimeType,
      size
    },
  };
};
