import {initActions} from '@dized/ui/dist/redux/ActionHelper';
import {filterByGameId, generateFilter} from '@dized/ui/dist/redux/FilterHelper';
import {patch, post, get} from '@dized/ui/dist/redux/RequestHelper';

const filterByType = (game, type, category) => game && type ? generateFilter({game, type, category}) : undefined;

const path = '/api/v1/project/projects';

export default initActions('ProjectActions', {
  GET_PROJECT: (uuid, type, category) => get(path, filterByType(uuid, type, category)),
  GET_PROJECTS: uuid => get(path, filterByGameId(uuid)),
  PATCH_PROJECT: params => patch(path, params),
  POST_PROJECT: params => post(path, params),
});
