export default `
  body {
    font-family: 'Cabin', sans-serif;
    font-size: 18px;
    min-width: 300px;
    color: #202020;
  }

  strong {
    font-weight: 600;
  }

  p {
    margin: 0;
  }

  .img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  .layout-container,
  .row,
  .col {
    box-sizing: border-box;
  }

  .layout-container {
    height: 100%;
    margin: 0 auto;
    max-width: 720px;
  }

  .row {
  padding: 15px 0;
  }

  /* 
    Clearfix! Need to test.
    It's needed to fix height problem of the parent element.
    Cause: floats.
  */
  .row:after {
      content: "";
      clear: both;
      display: table;
  }

  .row__img_left {
    margin: 0 0 15px 0;
  }

  .row__img_right {
    margin: 15px 0 0 0;
  }

  .icon {
    vertical-align: middle;
    max-width: 24px;
  }

  .note {
    background: #dbf6ff;
    font-family: 'Raleway', sans-serif;
    font-style: italic;
    font-size: 22px;
    border-radius: 4px;
    padding: 15px;
  }

  .note__header {
    color: #319bc3;
    font-size: 18px;
    line-height: 2;
  }

  .gallery__img {
    padding: 7.5px;
    max-width: 80%;
    margin: 0 auto;
  }

  @media only screen and (min-width: 560px) {
    .row__img_left {
      margin: 0 25px 0 0;
    }
    .row__img_right {
      margin: 0 0 0 25px;
    }
    .s-1 {
      float: left;
      width: 33.33%;
    }
    .s-2 {
      float: left;
      width: 66.66%;
    }
  }
`;