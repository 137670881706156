export function hasScopeFor(session, ...actionScopes) {
  const accessScopes = session.accessScopes || session.scopes;
  if (!accessScopes)
    return false;
  if (accessScopes.includes('*:*')) {
    return true;
  }
  for (let i = 0; i < actionScopes.length; i++) {
    const actionScope = actionScopes[i];
    if (actionScope.match(/^[\w]*:[\w]*$/)) {
      const parts = actionScope.split(':');
      if (
        accessScopes.includes(actionScope) ||
        accessScopes.includes(`${parts[0]}:*`) ||
        accessScopes.includes(`*:${parts[1]}`)
      ) {
        return true;
      }
    } else if (actionScope.match(/^[\w]*\.[\w]*$/)) {
      const parts = actionScope.split('.');
      if (
        accessScopes.includes(actionScope) ||
        accessScopes.includes(`${parts[0]}:*`)
      ) {
        return true;
      }
    } else {
      console.log(`Invalid action scope defination: ${actionScope}`);
    }
  }
  return false;
}
