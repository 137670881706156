import React, {useRef, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Button, Input, Toaster} from '@dized/ui';

import UserActions from '../../actions/UserActions';
import {generateLoginUrl} from '../../common/utils/urlUtils';

import background from '../Login/dized-logo-background.svg';
import logo from '../Login/dized.svg';
import {CREATE_TEST_ORGANIZATION_PATH} from '../Portal';

const Background = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: url(${background}) no-repeat 50% 5% #E3FBFE;
  align-items: center;
`;

const Container = styled.div`
  width: 90%;
  min-width: 500px;
  margin: 10px auto;
  padding: 10px 40px 20px;
  background-color: white;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  text-align: center;
  padding: 40px;
`;

const Paragraph = styled.div`
  margin: 5px auto;
`;

const StyledButton = styled(Button)`
  border: solid;
  border-color: transparent;

  &:focus {
    border-color: black;
  }
`;

const StyledInput = styled(Input)`
  border: solid;
  border-color: transparent;

  &:focus {
    border-color: black;
  }
`;

const changeFocus = (event, ref) => {
  if (event.target.value !== '' && event.key === 'Enter') ref.current.focus();
}

const mapStateToProps = store => ({
  invalidSession: store.session.invalidSession,
});

const mapDispatchToProps = dispatch => ({
  ...UserActions.bindActions(dispatch),
});

const Register = ({history: {replace}, invalidSession, LOGOUT, POST_REGISTER}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const lastNameRef = useRef();
  const [email, setEmail] = useState('');
  const emailRef = useRef();
  const [password, setPassword] = useState('');
  const passwordRef = useRef();
  const buttonRef = useRef();

  return (
    <Background>
      <Container>
        <Title>
          <img src={logo} alt="Dized"/>
          <h2>Register New User</h2>
        </Title>
        <Paragraph>
          First Name
        </Paragraph>
        <Paragraph>
          <StyledInput
            autoFocus
            onChange={e => setFirstName(e.target.value)}
            onKeyPress={event => changeFocus(event, lastNameRef)}
            value={firstName}
          />
        </Paragraph>
        <Paragraph>
          Last Name
        </Paragraph>
        <Paragraph>
          <StyledInput
            onChange={e => setLastName(e.target.value)}
            onKeyPress={event => changeFocus(event, emailRef)}
            ref={lastNameRef}
            value={lastName}
          />
        </Paragraph>
        <Paragraph>
          Email
        </Paragraph>
        <Paragraph>
          <StyledInput
            onChange={e => setEmail(e.target.value)}
            onKeyPress={event => changeFocus(event, passwordRef)}
            ref={emailRef}
            type="email"
            value={email}
          />
        </Paragraph>
        <Paragraph>
          Password
        </Paragraph>
        <Paragraph>
          <StyledInput
            onChange={e => setPassword(e.target.value)}
            onKeyPress={event => changeFocus(event, buttonRef)}
            ref={passwordRef}
            type="password"
            value={password}
          />
        </Paragraph>
        <StyledButton
          disabled={firstName === '' || lastName === '' || email === '' || password === ''}
          onClick={() => {
            return Promise.resolve()
              .then(() => POST_REGISTER(
                {
                  email,
                  firstname: firstName,
                  lastname: lastName,
                  password,
                  username: `${firstName} ${lastName}`,
                }
              ))
              .then(() => Toaster.success(`Account for '${email}' has been created.`))
              // NOTE: make sure current session is invalidated and then redirect
              //       to the test organization creation page via the login page.
              .then(() => {
                if (!invalidSession) return LOGOUT();
              })
              .then(() => replace(
                generateLoginUrl(
                  CREATE_TEST_ORGANIZATION_PATH,
                  `/${CREATE_TEST_ORGANIZATION_PATH}?newuser` // flag indicates new user to login page
                )
              ))
              .catch(error => {
                if (error.response)
                  Toaster.error(error.response.data.error.message);
                else if (error.message)
                  Toaster.error(error.message);
                else
                  Toaster.error('Something went wrong.');
              });
          }}
          ref={buttonRef}
        >
          Register
        </StyledButton>
      </Container>
    </Background>
  );
};

Register.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  invalidSession: PropTypes.bool.isRequired,
  LOGOUT: PropTypes.func.isRequired,
  POST_REGISTER: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
