import moment from 'moment';
import {palette} from './palette';
import {body} from './htmlLayout';
import {head} from './htmlLayout';

import {createTO} from '../utils/i18nUtils';

const createProjectDescriptionContext = {
  'application': 'org-portal',
  'description': 'project description',
};

const createProjectTitleContext = {
  'application': 'org-portal',
  'description': 'project title',
};

export const createProject = (params) => {
  return {
    game: params.uuid,
    type: params.type,
    category: params.category,
    description: createTO(params.description ?? '', createProjectDescriptionContext, 'en-US'),
    title: createTO(params.title, createProjectTitleContext, 'en-US'),
    locale: ['en-US'],
    created: moment().format(),
    updated: moment().format(),
    version: '',
    status: 'unpublished',
    organizationId: params.organizationId
  }
};

export const createRuleVersion = (params) => {
  return {
    projectUuid: params.uuid,
    title: params.title,
    created: moment().format(),
    updated: moment().format(),
    locale: ['en-US'],
    revision: 1,
    version: 'Anything you want',
    palette,
    menus: [{}],
    questions: [{}],
    rules: [{}],
    status: 'unpublished',
    html: {
      header: head(),
      body: body()
    },
    assets: {
      coverImage: {
        download: 'https://via.placeholder.com/193x$193?text=Click to set image'
      }
    }
  }
};

export const createGameVersion = (params) => {
  return {
    gameId: params.gameId,
    title: params.title,
    created_date: moment().format(),
    status: params.status,
    player_min: params.player_min,
    player_max: params.player_max,
    playing_time: params.playing_time,
    age_min: params.age_min,
    age_max: params.age_max,
    categories: params.categories,
    locales: params.locales,
    tagline: params.tagline,
    main: params.main,
    publishers: params.publishers,
    designers: params.designers,
    artists: params.artists,
    homepage: params.homepage,
    awards: params.awards,
    assets: params.assets
  }
};
