import {initActions} from '@dized/ui/dist/redux/ActionHelper';
import {del, get, post} from '@dized/ui/dist/redux/RequestHelper';

const path = '/api/v1/translation';

export default initActions('TranslationActions', {
  DELETE_GAME_LOCALE: (gameId, locale) => del(`${path}/game/${gameId}/${locale}`),
  DELETE_PROJECT_LOCALE: (projectId, locale) => del(`${path}/project/${projectId}/${locale}`),
  GET_GAME_LOCALES: gameId => get(`${path}/game/${gameId}`),
  GET_PROJECT_LOCALES: projectId => get(`${path}/project/${projectId}`),
  POST_GAME_LOCALE: (gameId, locale) => post(`${path}/game/${gameId}`, {locale}),
  POST_PROJECT_LOCALE: (projectId, locale) => post(`${path}/project/${projectId}`, {locale}),
});
