import AutosizeInput from 'react-input-autosize';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import classNames from 'classnames';

export class Input extends Component {
  render() {
    const {filter, ...rest} = this.props;
    return (
      <input className={inputClassNames(this.props, this.state)} {...rest} />
    )
  }
}

export class TextArea extends Component {
  render() {
    const {filter, ...rest} = this.props;
    return (
      <textarea
        className={inputClassNames(this.props, this.state)}
        {...rest} />
    )
  }
}

export class SmartInput extends Component {

    static defaultProps = {
      initValue: ''
    }

    static propTypes = {
      field: PropTypes.string.isRequired,
      table: PropTypes.string.isRequired
    }

    constructor(props) {
      super(props);
      this.state = {
        [this.props.field]: this.props.initValue
      }
      this.onChange = this.onChange.bind(this);
      this.onBlur = this.onBlur.bind(this);
    }

    onChange(event) {
      // React controls the input
      const {value} = event.target,
        {field} = this.props;
      this.setState({[field]: value});
    }

    onBlur() {
      // When fire => trigger callback which will update store
      const {field, value, table} = this.props;
      if (this.state[field] === value) return;
      this.props.onBlur({[table]: this.state});
    }

    render() {
      const value = this.state[this.props.field],
        {initValue, ...rest} = this.props;
      return (
        <AutosizeInput
          {...rest}
          value={value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          inputClassName={
            `${inputClassNames(this.props, this.state)} input_auto-size`
          }
        />
      )
    }
}

function inputClassNames(props, state) {
  const {field, filter} = props;
  return classNames({
    input: true,
    input_filter: filter,
    input_warning: state && !state[field] ? true : false
  })
}
