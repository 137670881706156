import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';

import {Button} from '@dized/ui';

import GameActions from '../../actions/GameActions';
import ProjectActions from '../../actions/ProjectActions';
import TranslationActions from '../../actions/TranslationActions';
import {assetToAO1} from '../../common/utils/assetUtils';
import {
  ProjectTypes,
  ProjectTypeNames,
  editProject,
  editProjectTranslations,
  reviewTutorial
} from '../../common/utils/projectUtils';
import AssetUI from '../../components/AssetUI';
import GameDetails, {DefaultImage, Image} from '../../components/GameDetails';
import {LocaleTable} from '../../components/LocaleTable';
import ProjectDetails from '../../components/ProjectDetails';
import {GridButton} from '../../components/GridDataTable';
import {Row, Title} from '../../components/StyledComponents';

const assetAttr = {
  mimeType: 'image/png, image/jpeg',
  locale: 'en-US',
  size: '[2048,2048]',
  min: true,
  max: true
};

const projectImageContext = {
  'application': 'org-portal',
  'description': 'project image',
};

const mapStateToProps = store => ({
  organization: store.db.organization,
  projects: store.db.projects,
});

const mapDispatchToProps = dispatch => ({
  ...GameActions.bindActions(dispatch),
  ...ProjectActions.bindActions(dispatch),
  ...TranslationActions.bindActions(dispatch),
});

class ProjectInfo extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        gameId: PropTypes.string.isRequired,
        projectId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    organization: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }).isRequired,
    projects: PropTypes.shape({
      projects: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }).isRequired,
        projectImage: PropTypes.shape({
          assetId: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }),
        status: PropTypes.string.isRequired,
        title: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }).isRequired,
        type: PropTypes.string.isRequired,
        uuid: PropTypes.string.isRequired,
      })),
    }).isRequired,
    DELETE_PROJECT_LOCALE: PropTypes.func.isRequired,
    GET_GAME: PropTypes.func.isRequired,
    GET_LATEST_REVISION: PropTypes.func.isRequired,
    GET_PROJECT_LOCALES: PropTypes.func.isRequired,
    GET_PROJECTS: PropTypes.func.isRequired,
    PATCH_PROJECT: PropTypes.func.isRequired,
    POST_PROJECT_LOCALE: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  state = {
    locales: [],
    project: {},
    showAssetUI: false,
    showProjectDetails: false,
  };

  componentDidMount() {
    const {match, GET_GAME, GET_LATEST_REVISION, GET_PROJECT_LOCALES, GET_PROJECTS} = this.props;
    const {gameId, projectId} = match.params;

    GET_GAME(gameId)
      .then(() => GET_LATEST_REVISION(gameId))
      .then(() => GET_PROJECTS(gameId))
      .then(this.getProjectDetails)
      .then(() => GET_PROJECT_LOCALES(projectId))
      .then(this.getLocales);

    document.title = 'Dized Portal: Project Information';
  }

  /**
   * Extends React Component lifecycle API with a promisified version of setState()
   *
   * @param {object} newState - new component state passed to this.setState()
   * @returns {Promise}       resolves when component state has been updated
   */
  setStatePromise(newState) {
    return new Promise(resolve => this.setState(newState, resolve));
  }

  getLocales = () => {
    const {match, GET_PROJECT_LOCALES} = this.props;
    const {projectId} = match.params;

    return Promise.resolve()
      .then(() => GET_PROJECT_LOCALES(projectId))
      .then(response => this.setStatePromise({locales: response.value.data}));
  };

  getProjectDetails = () => {
    const {match, projects: {projects}} = this.props;
    const {projectId} = match.params;
    const project = projects.find(({uuid}) => uuid === projectId);

    return this.setStatePromise({
      project: {...project},
    });
  };

  onAddLocale = locale => {
    const {match, POST_PROJECT_LOCALE} = this.props;
    const {projectId} = match.params;

    return Promise.resolve()
      .then(() => POST_PROJECT_LOCALE(projectId, locale))
      .then(this.getLocales);
  };

  onDeleteLocale = locale => {
    const {match, DELETE_PROJECT_LOCALE} = this.props;
    const {projectId} = match.params;

    return Promise.resolve()
      .then(() => DELETE_PROJECT_LOCALE(projectId, locale))
      .then(this.getLocales);
  };

  onDetailsClose = () => {
    this.setProjectDetails(false);
  };

  onDetailsConfirm = params => {
    const {match, PATCH_PROJECT} = this.props;
    const {project: {description: descriptionTR1, title: titleTR1}} = this.state;
    const {gameId} = match.params;
    const {description: descriptionText, title: titleText} = params;

    return Promise.resolve()
      .then(() => new Promise(resolve => this.setProjectDetails(false, resolve)))
      .then(() => PATCH_PROJECT({
        ...params,
        description: {
          ...descriptionTR1,
          text: descriptionText,
        },
        title: {
          ...titleTR1,
          text: titleText,
        },
        game: gameId,
        updated: moment().format(),
      }))
      .then(this.getProjectDetails);
  };

  onEditProjectClick() {
    const {match, organization} = this.props;
    const {project: {type}} = this.state;
    const {gameId, projectId} = match.params;

    return editProject(organization, gameId, projectId, type);
  }

  onEditTranslations = locale => {
    const {match} = this.props;
    const {projectId} = match.params;

    editProjectTranslations(projectId, locale);
  };

  onProjectImageSelect = asset => {
    const {match, PATCH_PROJECT} = this.props;
    const {project: {type, uuid}} = this.state;
    const {gameId} = match.params;

    return Promise.resolve()
      .then(() => PATCH_PROJECT({
        projectImage: assetToAO1(asset, projectImageContext),
        game: gameId,
        type,
        updated: moment().format(),
        uuid,
      }))
      .then(this.getProjectDetails);
  };

  onReviewTranslations = locale => {
    const {match, organization} = this.props;
    const {gameId} = match.params;

    reviewTutorial(organization, gameId, locale);
  };

  onUpdateProjectClick() {
    this.setProjectDetails(true);
  }

  setProjectDetails(showProjectDetails, callback) {
    this.setState({showProjectDetails}, callback);
  }

  render() {
    const {match, organization: {uuid: organizationId}} = this.props;
    const {locales, project, showAssetUI, showProjectDetails} = this.state;
    const {gameId} = match.params;
    const {
      description: {text: description} = {},
      projectImage,
      status,
      title: {text: title} = {},
      type
    } = project;
    const {assetId, url = DefaultImage} = projectImage || {};

    return (
      <>
        <GameDetails gameId={gameId}>
          <Button onClick={() => this.onEditProjectClick()}>Edit Project</Button>
        </GameDetails>
        <Row>
          <Image
            alt="Project Art"
            onClick={() => this.setState({showAssetUI: true})}
            src={url}
          />
          <Title>
            <h3>{`Project: ${title} - ${ProjectTypeNames[type]} - ${status}`}</h3>
          </Title>
          <Button onClick={() => this.onUpdateProjectClick()}>Edit Details</Button>
        </Row>
        {description && (
          <Row>
            <Title>
              <h4>{description}</h4>
            </Title>
          </Row>
        )}
        <LocaleTable
          locales={locales}
          localeActions={locale => (
            <>
              {type === ProjectTypes.TUTORIAL_CT && <GridButton onClick={() => this.onReviewTranslations(locale)}>Review</GridButton>}
              <GridButton onClick={() => this.onEditTranslations(locale)}>Edit</GridButton>
            </>
          )}
          onAddLocale={this.onAddLocale}
          onDeleteLocale={this.onDeleteLocale}
        />
        {showProjectDetails && (
          <ProjectDetails
            {...project}
            onClose={this.onDetailsClose}
            onConfirm={this.onDetailsConfirm}
          />
        )}
        <AssetUI
          attr={assetAttr}
          callBack={this.onProjectImageSelect}
          close={() => this.setState({showAssetUI: false})}
          currentAsset={assetId}
          organizationId={organizationId}
          show={showAssetUI}
        />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectInfo);
