import {push} from 'connected-react-router';

import {generateLoginUrl} from '../common/utils/urlUtils';

const apiMiddleware = ({dispatch, getState}) => next => async action => {
  // 401 "Unauthorized" response to action
  if (action.payload?.response?.status === 401) {
    const currentUrl = getState().router.location.pathname;
    redirectToLoginPage(currentUrl, dispatch);
  }

  return next(action);
};

function redirectToLoginPage(currentUrl, dispatch) {
  const [, organization, currentRoute] = currentUrl.match('^/?([^/]*).*?/?([^/]*)$');

  // don't redirect requests for
  // - register user page
  // - root URL
  // - organization login page
  if ((organization === 'register')                ||
      (organization === '' && currentRoute === '') ||
      (organization !== '' && currentRoute === 'login'))
    return;

  dispatch(push(generateLoginUrl(organization, currentUrl)));
}

export default apiMiddleware;
