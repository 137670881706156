import React, {Component} from 'react';
import {Icon, Input} from '@dized/ui';
import Modal from './modal';
import Dropdown from 'react-dropdown';

export default class Toolbar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      newFolderName: '',
      isEmptyTitle: '',
      modalHeader: '',
      value: '',
      action: '',
    };

    this.action = {
      create: 'create',
      delete: 'delete',
    };

    this.filters = [
      {label: 'Images', value: 'image'},
      {label: 'Audio', value: 'audio'},
      {label: 'Text', value: 'text'},
      {label: 'Other', value: 'other'}
    ];

    this.currentFilter = this.props.assets.typeFilter;
  }

  onChange = (event) => {
    const {value} = event.target;

    this.setState({
      newFolderName: value,
      isEmptyTitle: false
    });
  };

  onCancel = () => {
    this.setState({
      newFolderName: '',
      isEmptyTitle: false
    });
    this.onModalClose();
  };

  onCreateFolder = () => {
    const value = this.state.newFolderName;
    if (!value) return this.setState({isEmptyTitle: true});

    const folder = {
      name: value,
      parentId: this.props.currentFolder,
      parent: this.props.currentFolder,
      organizationId: this.props.organizationId
    };

    this.props.actions.CREATE_FOLDER(folder)
      .then(() => this.props.reloadContent()).catch(error => console.error('Failed to create folder: ', error));
    this.onModalClose();
  };

  onDelete = () => {
    this.props.type === 'asset' ?
      this.props.actions.DELETE_ASSET(this.props.selectedItem.id)
        .then(() => this.props.reloadContent()).catch(error => console.error('Failed to delete asset: ', error)) :
      this.props.actions.DELETE_FOLDER(this.props.selectedItem.uuid)
        .then(() => this.props.reloadContent()).catch(error => console.error('Failed to delete folder: ', error));
    this.onModalClose();
  };

  onModalClose = () => {
    this.props.actions.SHOW_MODAL(false);
    this.setState({action:''})
  }

  createFolder = () => {
    this.confirm = this.onCreateFolder;
    this.setState({action: this.action.create, modalHeader: 'Create New Folder', value: this.state.newFolderName});
    this.props.actions.SHOW_MODAL(true);
  };

  deleteItem = () => {
    this.confirm = this.onDelete;
    this.setState({action: this.action.delete, modalHeader: 'Delete ' + this.props.selectedItem.name + '?'});
    this.props.actions.SHOW_MODAL(true);
  };

  filter = (filter) => {
    this.currentFilter = filter.label;
    this.props.actions.FILTER_ASSETS(filter.value);
  };

  onFilter = (event) => {
    event.preventDefault();
    this.props.actions.FILTER_ASSETS(this.filters);
    this.onModalClose();
  };

  modalChildren = () => {
    switch (this.state.action) {
      case this.action.create:
        return (
          <Input
            placeholder="Title"
            maxLength={25}
            value={this.state.newFolderName}
            onChange={this.onChange}
            style={{border: this.state.isEmptyTitle ? '1px solid red' : '1px solid transparent'}}/>
        )
      case this.action.delete:
      default:
    }
  };

  uploadFile = (e) => {
    this.props.fileUpload(e);
  }

  render() {
    return (
      <React.Fragment>
        <div className="explorer-nav">
          <button className={'explorer-nav-button-up'} onClick={this.props.moveToParentFolder}>
            <Icon color="#000" size='large'>arrow_back</Icon>
          </button>
          <button className={'explorer-nav-button-folder'} onClick={this.uploadFile}>
            <Icon color="#000" size='large'>cloud_upload</Icon>
          </button>
          <button className={'explorer-nav-button-folder'} onClick={this.createFolder}>
            <Icon color="#000" size='large'>create_new_folder</Icon>
          </button>
          <button className={'explorer-nav-button-folder'} onClick={this.deleteItem}>
            <Icon color="#000" size='large'>delete_forever</Icon>
          </button>
          <Dropdown className='Dropdown-root-cust'
                    controlClassName={'Dropdown-control'}
                    menuClassName={'Dropdown-menu'}
                    arrowClassName={'Dropdown-arrow'}
                    onChange={this.filter}
                    options={this.filters}
                    value={this.currentFilter || 'Filter'}
                    placeholder="Select an option"/>
        </div>
        {this.state.action !== '' &&
          <Modal
            show={this.props.assets.modal.show}
            header={this.state.modalHeader}
            onCancel={this.onCancel}
            onConfirm={this.confirm}>
            <div className="modal__form">
              {this.modalChildren()}
            </div>
          </Modal>
        }
      </React.Fragment>
    )
  }
}
