import ShellActions from '../actions/ShellActions';

const initialState = {
  assetExplorerItem: '',
};

export default (store = initialState, action) => {
  switch (action.type) {
    case ShellActions.SHOW_ASSET_EXPLORER.FULFILLED:
      return {
        ...store,
        assetExplorerItem: action.payload.data,
      };
    case ShellActions.DISMISS_ASSET_EXPLORER.FULFILLED:
      return {
        ...store,
        assetExplorerItem: '',
      };
    default:
      return store;
  }
}
