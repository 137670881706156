import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {GameTile, Button, IconButton} from '@dized/ui';
import { hasScopeFor } from '../../common/utils/authHelper';

import './Games.css';
import GameActions from '../../actions/GameActions';
import {getStringFromTO} from '../../common/utils/i18nUtils';
import {editRules, editTutorial, reviewTutorial} from '../../common/utils/projectUtils';

const mapStateToProps = store => ({
  games: store.db.games,
  organization: store.db.organization,
  session: store.session.session
});

const mapDispatchToProps = dispatch => ({
  ...GameActions.bindActions(dispatch),
});

class Games extends Component {
  static propTypes = {
    games: PropTypes.shape({
      initialized: PropTypes.bool,
      gameList: PropTypes.array.isRequired,
      gameLookup: PropTypes.object.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    organization: PropTypes.shape({
      slug: PropTypes.string,
      uuid: PropTypes.string,
    }).isRequired,
    session: PropTypes.object.isRequired,
    GET_GAMES: PropTypes.func.isRequired,
    GET_LATEST_REVISION: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  componentDidMount() {
    if (this.props.organization.uuid) {
      const {games} = this.props;

      if (!games.initialized) {
        this.refreshGamesList();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.organization.uuid !== prevProps.organization.uuid) {
      const {games} = this.props;

      if (!games.initialized) {
        this.refreshGamesList();
      }
    }
    document.title = 'Dized Portal: Games';
  }

  refreshGamesList() {
    const {GET_GAMES, organization, GET_LATEST_REVISION} = this.props;

    GET_GAMES(organization.uuid).then(args => {
      args.value.data.forEach(game => {
        GET_LATEST_REVISION(game.uuid);
      });
    });
  }

  onGameTileClick = gameId => {
    const {history, organization} = this.props;
    history.push(`/${organization.slug}/game/${gameId}`);
  };

  onEditRulesButtonClick = gameId => {
    const {organization} = this.props;
    return editRules(organization, gameId);
  };

  onEditTutorialButtonClick = gameId => {
    const {organization} = this.props;
    return editTutorial(organization, gameId);
  };

  onReviewTutorialButtonClick = gameId => {
    const {organization} = this.props;
    return reviewTutorial(organization, gameId);
  };

  renderGameTiles() {
    const {games, session} = this.props;
    const gameList = games.gameList;
    const gameLookup = games.gameLookup;

    return gameList.map(gameId => {
      const game = gameLookup[gameId];
      const coverImage = game.assets && game.assets.boxart.download;

      return (
        <div className="Game-container" key={game.uuid}>
          <GameTile
            className="Game-tile"
            onClick={() => this.onGameTileClick(game.uuid)}
            title={getStringFromTO(game.title)}
            coverImage={coverImage}
            playerCountFrom={game.player_min}
            playerCountTo={game.player_max}
            gameLengthMin={game.playing_time}
            ageMin={game.age_min}
            ageMax={game.age_max}
            iconRules
          />
          <Button
            className="Games-editButton"
            secondary={true}
            onClick={() => this.onEditRulesButtonClick(game.uuid)}
          >
            Edit Rules
          </Button>
          {hasScopeFor(session, 'tutorial.') && (
            <div className="Games-editButton Games-tutorialButtons">
              {hasScopeFor(session, 'tutorial:WRITE') && (
                <Button
                  className="Games-editTutorialButton"
                  secondary={true}
                  onClick={() => this.onEditTutorialButtonClick(game.uuid)}
                >
                  Edit Tutorial
                </Button>
              )}
              {hasScopeFor(session, 'tutorial:READ') && (
                <IconButton
                  M
                  className="Games-reviewTutorialButton"
                  onClick={() => this.onReviewTutorialButtonClick(game.uuid)}
                >
                  visibility
                </IconButton>
              )}
            </div>
          )}
        </div>
      );
    });
  }

  render() {
    return <div className="Games-list">{this.renderGameTiles()}</div>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Games);
