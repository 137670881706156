import OrganizationActions from '../actions/OrganizationActions';
import UserActions from '../actions/UserActions';

const initialState = {
  uuid: '',
  name: '',
  slug: '',
  accessPaths: [],
  invalid: true
};

export default (store = initialState, action) => {
  const {payload} = action;

  switch (action.type) {
    case OrganizationActions.GET_ORGANIZATION.FULFILLED: {
      const orgData = payload.data[0];
      let invalid = false;

      if (payload.data.length < 1) {
        console.warn('WARNING: Did not return a valid Organization');
        invalid = true;
      }

      if (payload.data.length > 1) {
        console.warn('WARNING: Too many organization objects returned, using the first one in the list');
      }

      return {
        ...store,
        ...orgData,
        invalid: invalid
      };
    }

    case UserActions.LOGOUT.FULFILLED:
      return {...initialState}; // reset state on logout

    default:
      return store;
  }
}
