import {initActions} from '@dized/ui/dist/redux/ActionHelper';
import {post} from '@dized/ui/dist/redux/RequestHelper';

const path = '/api/v1/user/Users';

export default initActions('OrganizationActions', {
  CREATE_TEST_ORGANIZATION: name => post(`${path}/testOrganization`, {name}),
  LOGIN: credentials => post(`${path}/login`, {
    'email': credentials.username,
    'password': credentials.password
  }),
  LOGOUT: () => post(`${path}/logout`),
  POST_REGISTER: data => post(`${path}/register`, data),
});
